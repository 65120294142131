/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import Editor from "@monaco-editor/react";
import { BugReportOutlined, Close, FileUploadOutlined, HelpOutline, PhotoCamera, PlayCircleFilled } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import GridViewIcon from "@mui/icons-material/GridView";
import HeartBrokenRoundedIcon from "@mui/icons-material/HeartBrokenRounded";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ListIcon from "@mui/icons-material/List";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from "@mui/icons-material/Search";
import SettingIcon from "@mui/icons-material/Settings";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { LoadingButton, TabList } from "@mui/lab";
import AppBar from "@mui/material/AppBar";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Link from '@mui/material/Link';
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import { drandom, dtime, TStringCallback, TVoidCalBack, yahooFin } from 'corexxx';
import 'firebase/storage';
import ReactQuill from 'react-quill';
import './css/dweb.css'; // Import CSS file for styling
import './css/quill.snow.css';

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Tooltip } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertColor,
  AlertTitle,
  Avatar,
  Box,
  ButtonGroup,
  Chip,
  CircularProgress,
  CircularProgressProps,
  createTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuList,
  Modal,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import { ThemeProvider } from "@mui/material/styles";
import { DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { dassert, dcolors, dlog, dnetwork, TAnyCallback, TObjCalBack, TObject } from "corexxx";
import { FirebaseOptions } from "firebase/app";
import { getDownloadURL, ref } from "firebase/storage";
import jwt_decode from "jwt-decode";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import { Route, useNavigate, useSearchParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import _ from "underscore";
import { buildConfig } from "../version";
import { CommonTS } from "./corexxx-ext";
import { DHook } from "./DHook";
import { DWebTS } from "./DWebTS";
import image_placeholder from "./img/image_placeholder.png";
import unplugged from "./img/unplugged.png";
import { DrawerNestedNavigationDebugOnly } from "./screens/Register";
var beautify_js = require("js-beautify");




const AUTH_ENDPOINT = "https://simplestore.dipankar.co.in/api/simple_auth";
export namespace DWeb {
  /// SOME COMMON MODELS
  export type TBasicInfo = {
    icon?: any,
    title?: string,
    subtitle?: string,
    extra?: any
  }
  /// END OF COMMON MODELS
  /******************************************************
   * Basic Components: Row, Col, Box, Text, Html, Space, Separator etc,
   *
   *********************************************************/
  // define Row view
  export const DRow = ({ children, style, center }: { children?: any; style?: CSSProperties, center?: boolean }) => {

    return <div style={{
      display: "flex", flex: 1, flexDirection: "row",
      alignItems: center ? "center" : 'initial',
      flexWrap: "wrap",
      ...style,
    }}>{children || null}</div>;
  };

  // Define ceneter
  export const DCenter = ({ children, style }: { children?: any; style?: CSSProperties }) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          ...style,
        }}
      >
        {children || null}
      </div>
    );
  };

  export const DRowResponsive = ({ children, style }: { children?: any; style?: CSSProperties }) => {
    let devicetype = DHook.useDeviceType();
    return (
      <div style={{ display: "flex", flex: 1, flexDirection: devicetype == "desktop" ? "row" : "column", ...style }}>
        {children || null}
      </div>
    );
  };

  export const DSpace = ({ style }: { style?: CSSProperties }) => {
    return <div style={{ flex: 1, display: "flex", ...style }} />;
  };
  export const DCol = ({ children, style, id, className }: { children?: any; style?: CSSProperties; id?: string, className?: string }) => {
    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column", ...style }} id={id} className={className}>
        {children || null}
      </div>
    );
  };
  export const DBox = ({ children, style }: { children?: any; style?: CSSProperties }) => {
    return (
      <DCol
        style={{
          borderRadius: 4,
          display: "flex",
          background: "white",
          padding: 20,
          boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          position: "relative",
          ...style,
        }}
      >
        {children || null}
      </DCol>
    );
  };
  export const DLoadingUI = () => {
    return (
      <DCol style={{ alignItems: "center" }}>
        <CircularProgress size={20} />
        <DText style={{ color: dcolors.pallets.grey600, marginTop: 10 }}>Loading ...</DText>
      </DCol>
    );
  };
  export const DText = ({
    children,
    style,
    mode,
    id,
    onClick
  }: {
    id?: string;
    children: any;
    style?: CSSProperties;
    mode?: "title" | "subtitle" | "topper" | "page_subheader" | "footnote";
    onClick?: () => void
  }) => {
    let style1: CSSProperties = { color: dcolors.pallets.grey700 };
    switch (mode) {
      case "title":
        style1 = { ...style1, fontWeight: "bold", fontSize: 20 };
        break;
      case "page_subheader":
        style1 = { ...style1, fontWeight: "bold", fontSize: 17.5, textTransform: "uppercase", marginBottom: 16 };
        break;
      case "subtitle":
        style1 = { ...style1, fontSize: 15, ...style1 };
        break;
      case "topper":
        style1 = { ...style1, textTransform: "uppercase", fontSize: 13.5, color: dcolors.pallets.grey400, ...style1 };
        break;
      case "footnote":
        style1 = { ...style1, color: dcolors.pallets.grey600, marginTop: 10, fontSize: 10, textAlign: "center" };
    }
    return (
      <Typography id={id} style={{ padding: 0, ...style1, ...style }} onClick={onClick}>
        {children || "..Missing text?"}
      </Typography>
    );
  };

  export const DInformationView = ({ title, subtitle }: { title: string; subtitle: string }) => {
    return (
      <DWeb.DBox
        style={{
          margin: 20,
          width: 380,
          maxHeight: 380,
          alignSelf: "center",
          justifyContent: "center",
          flex: 1,
          padding: 35,
        }}
      >
        <DWeb.DText style={{ alignSelf: "center", color: dcolors.pallets.grey500, fontSize: 20, marginBottom: 20 }}>{title}</DWeb.DText>
        <DWeb.DText style={{ alignSelf: "center", textAlign: "center", color: dcolors.pallets.grey700 }}>{subtitle}</DWeb.DText>
      </DWeb.DBox>
    );
  };

  export const Html = ({ html, style }: { html: string; style?: CSSProperties }) => {
    return (
      <Typography style={{ color: dcolors.pallets.grey800, ...style }}>
        <span dangerouslySetInnerHTML={{ __html: html }}></span>
      </Typography>
    );
  };

  export const Separator = ({ style }: { style?: CSSProperties }) => (
    <div style={{ width: "100%", height: 1, borderTop: "1px solid #f1f1f1", ...style }} />
  );

  /******************************************************
   * App Init and App Wrappers
   *
   *********************************************************/
  export const DAppInit = React.memo(({ appConfig }: { appConfig: TAppConfig }) => {
    dlog.d("DAppInit called ");

    const appCommand = useAppCommand();
    const [searchParams, setSearchParams] = useSearchParams();
    const path = DHook.useReactPath();
    //init
    React.useEffect(() => {
      appCommand.init(appConfig);
      if (appConfig.google_analytics_tag) {
        DWebTS.danalytics.init(appConfig.google_analytics_tag);
      }
    }, []);

    // this hook will log the path
    React.useEffect(() => {
      DWebTS.danalytics.pageview(window.location.pathname + window.location.search);
    }, [path]);

    React.useEffect(() => {
      /* tslint:disable */
      let list: TObject = {};
      for (let i of [...(searchParams as any).keys()]) {
        if (i.startsWith("gk_")) {
          list[i] = searchParams.get(i) === "1" || searchParams.get(i) === "true";
        }
      }
      appCommand.setGkState(list);
    }, [searchParams]);
    return <></>;
  });

  export const DAppWrapper = ({ children }: { children: any }) => {
    const [open, setOpen] = React.useState(false);
    const [notification] = useRecoilState<TNotification | undefined>(notificationState);
    const [loading] = useRecoilState<boolean>(loadingStateAtom);
    React.useEffect(() => {
      if (notification) {
        setOpen(true);
      }
    }, [notification]);
    // GK enabled
    const theme = createTheme({
      typography: {
        fontFamily: ["Source Sans Pro", "Roboto", "Merriweather", "Lato", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <DWeb.DCol>
          {children}
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={(event, reason) => {
              if (reason !== "clickaway") {
                setOpen(false);
              }
            }}
          >
            {notification ? (
              <Alert onClose={() => setOpen(false)} severity={notification.type} sx={{ width: "100%" }}>
                {notification.msg}
              </Alert>
            ) : (
              <p>none</p>
            )}
          </Snackbar>
          {loading ? (
            <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 1101 }} className="loading">
              <LinearProgress />
            </div>
          ) : null}
        </DWeb.DCol>
      </ThemeProvider>
    );
  };

  /******************************************************
   * Delay Rendering
   *
   *********************************************************/

  export const DelayRenderer = ({ url, renderOnData }: { url: string; renderOnData: (data: any) => JSX.Element }) => {
    let [data, setData] = React.useState<any | undefined>();
    let [loading, setLoading] = React.useState(false);
    let [error, setError] = React.useState<string | undefined>();
    let appCommand = useAppCommand();
    React.useEffect(() => {
      (async () => {
        setLoading(true);
        setData(undefined);
        setError(undefined);
        appCommand.loading(true);

        try {
          let data = await dnetwork.getSimpleStore(url);
          setData(data);
        } catch (e) {
          setError("Not able to load the data. You might having an network issue");
        }
        setLoading(false);
        appCommand.loading(false);
      })();
    }, [url]);

    const safeRender = useCallback(() => {
      try {
        return renderOnData(data);
      } catch (e: any) {
        console.error(e);
        return <p style={{ color: dcolors.pallets.red400 }}> Not able to show the data due to internal error: {e.message} </p>;
      }
    }, [data]);

    return (
      <DWeb.DCol>
        {error ? <Alert severity="error">{"Not able to load this content"}</Alert> : null}
        {data ? safeRender() : null}
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#ffffff88",
            }}
          >
            <CircularProgress style={{ textAlign: "center" }} />
          </div>
        ) : null}
      </DWeb.DCol>
    );
  };

  /******************************************************
   * App Commands and Hooks
   *
   *********************************************************/
  export type TAppConfig = {
    app_id: string;
    app_name: string;
    primary_color: string; // must ahve a primary color
    logo?: any;
    subscription_key?: string; // we define one subscription only
    google_analytics_tag?: string;
    stripe_public_key?: string;
    seo?: TSeoConfig;
    google_client_id?: string; // this is mainly for google signin
    firebase_config?: FirebaseOptions;
    //social
    social?: {
      release_note?: string;
      email?: string;
      fb_page?: string;
      linkedin?: string;
      whatsapp?: string;
      twitter?: string;
    };
    // More app info
    app_slogan?: string;
    app_subtext?: string;
  };

  type TNotification = { type: AlertColor; msg: string };
  type TSubscription = { subscription_key: string; status: string; expiry_date: string; recept_url: string };
  type TAccountInfo = {
    _id: string;
    username: string;
    auth_token_: string;
    email: string;
    image: string;
    name: string;
    subscription_list?: TSubscription[];
    [key: string]: any;
  };
  const notificationState = atom<TNotification | undefined>({
    key: "notification", // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value)
  });
  const { persistAtom } = recoilPersist();
  const accountStateAtom = atom<TAccountInfo | undefined>({
    key: "account_state",
    default: undefined,
    effects_UNSTABLE: [persistAtom],
  });
  const loadingStateAtom = atom<boolean>({ key: "loading_state", default: false });
  const gkStateAtom = atom<TObject>({
    key: "gk_state",
    default: {},
    // effects_UNSTABLE: [persistAtom],
  });

  let _appConfig: TAppConfig | undefined = undefined;
  export function setAppConfig(config: TAppConfig) {
    _appConfig = config;
  }

  export type TRequestOverride = {
    allowAnonymous?: boolean
  }
  export const useAppCommand = () => {
    const [_notification, set_notification] = useRecoilState<TNotification | undefined>(notificationState);
    const [accountState, setAccountState] = useRecoilState(accountStateAtom);
    const [_loadingState, setLoadingState] = useRecoilState(loadingStateAtom);
    const [gkState, setGkState] = useRecoilState(gkStateAtom);
    const navigtaion = useNavigate();

    // reload the user - This was needed when suscription changed
    const reloadUser = useCallback(async () => {
      if (!accountState) {
        dlog.d("not able to load as user not login");
      }
      let account = await dnetwork.getSimpleStore(AUTH_ENDPOINT + `/get_info?auth_token_=${accountState?.auth_token_}`);
      const ds = DWebTS.decode(account.out);
      setAccountState(ds);
    }, [accountState]);

    function isDebugMode() {
      if (gkState["gk_make_prod"]) {
        return false;
      }
      return (gkState["gk_debugging"] || process.env.NODE_ENV === "development");
    }

    return {
      init: async (appConfig: TAppConfig) => {
        dlog.d("useAppCommand inited");
        _appConfig = appConfig;
        DWebTS.initAppTs(appConfig);
      },
      getAppConfig: () => {
        return _appConfig;
      },
      setNotification: (noti: TNotification) => {
        set_notification(noti);
      },
      accountState: accountState,
      isLoggedIn: accountState?._id != undefined,
      setUserLogin: (info: TAccountInfo) => {
        setAccountState(info);
      },

      reloadUser: reloadUser,
      updateAccount: async (data: TObject) => {
        dassert.verifyNotNullAndUndef(accountState, "missing account state");
        await dnetwork.postSimpleStore(AUTH_ENDPOINT + "/update", { auth_token: accountState!!.auth_token_, _id: accountState!!._id, ...data });
        await reloadUser();
      },

      logout: () => {
        setAccountState(undefined);
      },


      loading: (loading: boolean) => {
        setLoadingState(loading);
      },
      // subscriptions
      getSubscription: useCallback((): TSubscription | null => {
        if (!accountState || !accountState.subscription_list || !_.isArray(accountState.subscription_list)) {
          return null;
        }
        let data = accountState.subscription_list?.filter((y) => {
          if (y.subscription_key == _appConfig?.subscription_key) {
            if (new Date(y.expiry_date) > new Date()) {
              return true;
            }
          }
          return false;
        });
        if (data.length == 0) {
          return null;
        }
        return data[0];
      }, [accountState]),
      // Gk states
      gkState: gkState,
      setGkState: (str: TObject) => {
        setGkState({ ...gkState, ...str });
      },
      // use http://localhost:3000/account?gk_debugging=1 for setting the GK On
      isDebugMode: () => {
        // test as production
        // example force to make as prod http://localhost:3000/dashboard?gk_make_prod=true
        return isDebugMode()
      },
      isFishFooding: () => {
        return gkState['gk_fishfooding'] == '1' || isDebugMode()
      },
      isDogfooding: () => {
        return gkState['gk_dogfooding'] == '1'
      },
      isGkEnabled: (str: string) => {
        return gkState[str] != null && gkState[str] != undefined
      },
      fireAndForget: async (func: () => void, config?: { success_msg?: string; error_msg?: string; rethrow?: boolean }): Promise<any> => {
        try {
          let result = await func();
          set_notification({ type: "success", msg: "Success!" });
          return result;
        } catch (e: any) {
          set_notification({ type: "error", msg: "Error! " + e.message });
          if (config?.rethrow != false) {
            throw Error("Error Happened. " + e.message);
          }
        }
      },


      // == Network helper functions ==
      authCookies: { auth_token_: accountState?.auth_token_ },
      postSimpleStore: async (url: string, data: TObject, config?: TRequestOverride) => {
        return await dnetwork.postSimpleStore(url, { auth_token_: accountState?.auth_token_ || (config?.allowAnonymous ? "anonymous" : null), ...data, })
      },
      getSimpleStore: async (url: string, config?: TRequestOverride) => {
        // this is anyway a post to pass the auth_token_
        return await dnetwork.postSimpleStore(url, { auth_token_: accountState?.auth_token_ || (config?.allowAnonymous ? "anonymous" : null) })
      },
      // Navigation herper
      navigate: (target: string, data?: TObject) => {
        /*
         In the target view use the code to fetch the data
             const location = useLocation();
            const item: TObject = location.state;
        */
        navigtaion(target, { state: data });
      },
      back: () => navigtaion(-1),

      state: {
        // TODO: Have exopairy dates as well
        has_subscription: accountState?.subscription_list?.filter(x => x.subscription_key == _appConfig?.app_id).length == 1,
        is_logged_in: !accountState?._id
      },
      api: {
        go_back: () => navigtaion(-1),
      }
    };
  };

  // custom Hooks 1: useLocalStorage
  function getStorageValue(key: string, defaultValue: TObject) {
    const saved = localStorage.getItem(key) as string;
    const initial = JSON.parse(saved);
    return initial || defaultValue;
  }

  export const useLocalStorage = (key: string, defaultValue: TObject) => {
    const [value, setValue] = React.useState(() => {
      return getStorageValue(key, defaultValue);
    });

    React.useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value, defaultValue]);

    return [value, setValue];
  };

  /******************************************************
   * Account Pages
   *
   *********************************************************/
  // thsi provides all in one account screen
  export const AccountMenu = ({ style }: { style?: CSSProperties }) => {
    let appCommand = useAppCommand();
    return appCommand.accountState ? (
      <DWeb.DRow style={{ flex: 0 }}>
        <Button>
          <Avatar
            sx={{ width: 32, height: 32 }}
            alt={appCommand.accountState.name}
            src={appCommand.accountState.image}
            onClick={() => {
              appCommand.navigate("/account");
            }}
          />
        </Button>
      </DWeb.DRow>
    ) : (
      <Button
        style={{ color: "white" }}
        onClick={() => {
          appCommand.navigate("/account");
        }}
      >
        Join now
      </Button>
    );
  };

  export const AccountScreen = ({
    title,
    config,
  }: {
    title?: string;
    config?: { style?: CSSProperties; extra_ele?: JSX.Element; extra_link?: TNavigationItem[] };
  }) => {
    let appCommand = useAppCommand();
    let [username, set_username] = React.useState("");
    let [token, set_token] = React.useState("");
    let [email, set_email] = React.useState("");
    let [state, set_state] = React.useState<"signin" | "signup" | "forgetpassword" | "user_info">(
      appCommand.accountState?.auth_token_ ? "user_info" : "signin"
    );
    let [password, set_password] = React.useState("");
    let [error, set_error] = React.useState("");
    let [loading, set_loading] = React.useState(false);
    let [step, set_step] = React.useState(1);
    function clear() {
      set_email("");
      set_password("");
      set_username("");
      set_error("");
      set_token("");
    }
    React.useEffect(() => {
      clear();
    }, []);

    React.useEffect(() => {
      if (appCommand.accountState) {
        set_state("user_info");
      } else {
        set_state("signin");
      }
    }, [appCommand.accountState]);

    return (
      <DWeb.DCol style={{ maxWidth: 420, minHeight: 480, ...config?.style }}>
        {title ? (
          <>
            <Typography
              style={{ color: dcolors.pallets.blue600, fontSize: 15, textAlign: "center", textTransform: "uppercase", paddingBottom: 10 }}
            >
              {title}
            </Typography>
            <Separator style={{ marginBottom: 20 }} />
          </>
        ) : null}

        <DWeb.DCol style={{ padding: 0, width: "100%", boxSizing: "border-box" }}>
          {error.length > 0 ? (
            <Alert severity="error" style={{ marginBottom: 10 }}>
              {error}
            </Alert>
          ) : null}

          {state == "forgetpassword" ? (
            <>
              <DCol style={{ display: step == 1 ? "flex" : "none" }}>
                <DText style={{ marginBottom: 20 }}>Step 1: Enter your username and get Token via Mail</DText>
                <TextField
                  id="outlined-basic"
                  label="Enter Username"
                  variant="outlined"
                  style={{}}
                  value={username}
                  onChange={(e) => set_username(e.target.value)}
                />
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  style={{ width: "100%", marginTop: 24 }}
                  onClick={async () => {
                    set_loading(true);
                    set_error("");
                    try {
                      let res = await dnetwork.postSimpleStore(AUTH_ENDPOINT + "/forgot_password", {
                        username: username,
                      });
                      set_error(res.msg);
                      set_step(2);
                    } catch (e: any) {
                      set_error(e.message);
                    }
                    set_loading(false);
                  }}
                >
                  Send Token via mail
                </LoadingButton>
              </DCol>
              <DCol style={{ display: step == 2 ? "flex" : "none" }}>
                <DText style={{ marginBottom: 0, marginTop: 20 }}>Step 2: Enter the token and new password</DText>
                <TextField
                  id="outlined-basic"
                  label="Token"
                  variant="outlined"
                  style={{ marginTop: 24 }}
                  type="text"
                  value={token}
                  onChange={(e) => set_token(e.target.value)}
                ></TextField>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  style={{ marginTop: 24 }}
                  type="password"
                  value={password}
                  onChange={(e) => set_password(e.target.value)}
                ></TextField>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  style={{ width: "100%", marginTop: 24 }}
                  onClick={async () => {
                    set_loading(true);
                    set_error("");
                    try {
                      let res = await dnetwork.postSimpleStore(AUTH_ENDPOINT + "/reset_password", {
                        username: username,
                        short_lived_token: token,
                        password_: password,
                      });
                      clear();
                      set_error(res.msg);
                      set_state("signin");
                      set_step(1);
                    } catch (e: any) {
                      set_error(e.message);
                    }
                    set_loading(false);
                  }}
                >
                  Reset password
                </LoadingButton>
              </DCol>
            </>
          ) : null}

          {state == "signin" ? (
            <>
              <DText style={{ marginBottom: 20, fontSize: 20 }}>Sign In</DText>
              <DText style={{ marginBottom: 20, fontSize: 15, color: dcolors.pallets.grey500 }}>Note: You can utilize the same username and password to log in to multiple applications within the Grodok family.</DText>
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                style={{}}
                value={username}
                onChange={(e) => set_username(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                style={{ marginTop: 24 }}
                type="password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              ></TextField>
              <LoadingButton
                loading={loading}
                variant="contained"
                style={{ width: "100%", marginTop: 24 }}
                onClick={async () => {
                  set_loading(true);
                  set_error("");
                  try {
                    let res = await dnetwork.postSimpleStore(AUTH_ENDPOINT + "/login", {
                      username: username,
                      password_: password,
                    });
                    appCommand.setUserLogin(DWebTS.decode(res.out));
                    clear();
                  } catch (e) {
                    set_error("Not able to login");
                  }
                  set_loading(false);
                }}
              >
                Sign In
              </LoadingButton>
              <Button
                style={{ marginTop: 20, textTransform: "none" }}
                onClick={() => {
                  set_state("signup");
                  clear();
                  DWebTS.danalytics.report_action("signup_btn_clicked");
                }}
              >
                No account yet? Register now
              </Button>
              <Button
                style={{ marginTop: 0, textTransform: "none" }}
                onClick={() => {
                  set_state("forgetpassword");
                  clear();
                  DWebTS.danalytics.report_action("forgetpassword_btn_clicked");
                }}
              >
                Forget password? Reset now.
              </Button>
              {appCommand.getAppConfig()?.google_client_id ? (
                <>
                  <DWeb.DRow style={{ marginTop: 10, marginBottom: 10 }}>
                    <DWeb.DSpace style={{ border: "1px solid #f1f1f1", marginRight: 10 }} />
                    <DWeb.DText>OR</DWeb.DText>
                    <DWeb.DSpace style={{ border: "1px solid #f1f1f1", marginRight: 10 }} />
                  </DWeb.DRow>
                  <DWeb.GoogleLoginButton google_client_id={appCommand.getAppConfig()?.google_client_id!!} />
                </>
              ) : null}
            </>
          ) : null}

          {state == "signup" ? (
            <>
              <DWeb.DText style={{ marginBottom: 20, fontSize: 20 }}>Create new account</DWeb.DText>
              <DText style={{ marginBottom: 20, fontSize: 15, color: dcolors.pallets.grey500 }}>Note: You can utilize the same username and password to log in to multiple applications within the Grodok family.</DText>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ marginBottom: 24 }}
                value={email}
                onChange={(e) => set_email(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                style={{}}
                value={username}
                onChange={(e) => set_username(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                style={{ marginTop: 24 }}
                type="password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              ></TextField>
              <LoadingButton
                loading={loading}
                variant="contained"
                style={{ width: "100%", marginTop: 24 }}
                onClick={async () => {
                  set_loading(true);
                  set_error("");
                  try {
                    await dnetwork.postSimpleStore(AUTH_ENDPOINT + "/register", {
                      email: email,
                      username: username,
                      password_: password,
                    });
                    set_error("registered Successfully. Now please login to continue");
                    set_state("signin");
                    clear();
                  } catch (e: any) {
                    set_error(`Not able to register. ${e.message}`);
                  }
                  set_loading(false);
                  DWebTS.danalytics.report_action("register_btn_clicked");
                }}
              >
                Register
              </LoadingButton>
              <Button
                style={{ marginTop: 20, textTransform: "none" }}
                onClick={() => {
                  set_state("signin");
                  clear();
                  DWebTS.danalytics.report_action("signin_btn_clicked");
                }}
              >
                Already having a account? login.
              </Button>
            </>
          ) : null}

          {state == "user_info" && appCommand.accountState ? (
            <>
              <DWeb.DCol style={{ alignItems: "center", flex: 0 }}>
                <DWeb.DRow style={{ width: "100%", marginBottom: 30, position: "relative" }}>
                  <IconButton href="/" style={{ marginLeft: -10, marginTop: -10 }}>
                    <HomeIcon />
                  </IconButton>
                  <DWeb.DSpace />
                  <DWeb.DFormDialog
                    prefill={appCommand.accountState}
                    model={{
                      data: [
                        { type: "input", name: "name" },
                        { type: "number", name: "phone" },
                        { type: "input", name: "username", disabled: true },
                        { type: "input", name: "email", disabled: true },
                      ],
                    }}
                    dialogConfig={{ title: "Upload your account", triggerIcon: <EditIcon />, triggerStyle: { marginRight: -10, marginTop: -10 } }}
                    onSubmit={(obj) => {
                      appCommand.updateAccount(obj);
                    }}
                  />
                </DWeb.DRow>
                <DAvatarWithChangePicture
                  alt={appCommand.accountState?.name}
                  src={appCommand.accountState?.image}
                  sx={{ width: 80, height: 80 }}
                  onChange={async (file) => {
                    const path = await DWebTS.uploadAFile(file, { file_name: appCommand.accountState?._id + "profile_pic.png", user_id: appCommand.accountState?.username || '' });
                    await appCommand.updateAccount({ image: path });
                  }}
                />
                <DText style={{ fontWeight: "bold", marginTop: 20 }}>{appCommand?.accountState?.name || "Unknown"}({appCommand?.accountState.username})</DText>
                {appCommand?.accountState?.email ? <DText style={{ marginBottom: 0 }}>{appCommand?.accountState?.email}</DText> : null}
                {appCommand?.accountState?.phone ? <DText style={{ marginBottom: 20 }}>Phone:{appCommand?.accountState?.phone}</DText> : null}
              </DWeb.DCol>
              <DSpace />
              {config?.extra_ele}
              <DWeb.DCol style={{ flex: 0 }}>
                {config?.extra_link?.map((y, idy) => (
                  <Button key={idy} variant="outlined" href={y.href} style={{ marginBottom: 10 }}>
                    {y.text}
                  </Button>
                ))}
                <Button
                  variant="contained"
                  style={{ background: dcolors.pallets.red500, marginTop: 20 }}
                  onClick={() => {
                    appCommand.logout();
                    clear();
                    DWebTS.danalytics.report_action("logout_btn_clicked");
                  }}
                >
                  Logout
                </Button>
              </DWeb.DCol>
            </>
          ) : null}
        </DWeb.DCol>
      </DWeb.DCol>
    );
  };

  export type TSavedCode = {
    _id?: string;
    title?: string;
    code: string;
  };

  /******************************************************
   * Editors
   *
   *********************************************************/
  export function CodeCompiler({
    style,
    onSave,
    savedCode,
    height,
    extraButton,
  }: {
    style?: CSSProperties;
    onSave?: TStringCallback;
    savedCode?: TSavedCode;
    height?: string;
    extraButton?: JSX.Element;
  }) {
    let [code, setCode] = React.useState<string>(savedCode?.code || "");

    let [debug, setDebug] = React.useState<boolean>(false);
    let [output, setOutput] = React.useState<string>("");
    let appCommand = useAppCommand();
    let [loading, setLoading] = React.useState(false);
    const editor = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    /* -- WE DONT need this
    useScript({
      src: "https://cdnjs.cloudflare.com/ajax/libs/js-beautify/1.14.7/beautify.js",
      onload: () => {
        console.log("script js-beautify loaded!");
        // @ts-ignore:
        js_beautify(code, { indent_size: 2, space_in_empty_paren: true });
      },
    });
    */

    const handleClose = () => {
      setAnchorEl(null);
    };
    React.useEffect(() => {
      editor.current?.setValue(savedCode?.code || "");
    }, [savedCode]);

    async function execute() {
      setLoading(true);
      try {
        let res = (await dnetwork.post(
          debug ? "http://localhost/api/utils/rce/execute" : "https://simplestore.dipankar.co.in/api/utils/rce/execute",
          {
            _dev_token: "root",
            lang: "cpp",
            code: code,
          }
        )) as TObject;
        appCommand.setNotification({ type: res.status, msg: res.msg });
        console.log(res);

        setOutput(`${res.out.html || res.out}<p>(Note: Time taken to execute: ${res.out.time_taken} ms)`);
      } catch (e: any) {
        console.log(e);
        appCommand.setNotification({ type: "error", msg: e.message });
      }
      setLoading(false);
    }

    return (
      <DWeb.DCol style={{ position: "relative", ...style, display: "flex" }}>
        <DWeb.DRow style={{}}>
          <CountUp
            start={0}
            end={3600}
            duration={60 * 60}
            formattingFn={(timer) => {
              let seconds = timer % 60;
              let minutes = Math.floor(timer / 60) % 60;
              let hours = Math.floor(timer / 60 / 60);
              return (
                (hours > 9 ? hours : "0" + hours) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds)
              );
            }}
          >
            {({ countUpRef, start }) => (
              <ButtonGroup style={{ marginRight: 10 }}>
                <Button>
                  <span ref={countUpRef} />
                </Button>
                <Button onClick={start}>Reset</Button>
              </ButtonGroup>
            )}
          </CountUp>
          <DWeb.DText>Code Execution Engine</DWeb.DText>
          <DWeb.DSpace />
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
            <LoadingButton onClick={execute} loading={loading} variant="outlined">
              <PlayCircleFilled style={{ marginRight: 10 }} />
              Run
            </LoadingButton>
            <Button
              onClick={() => {
                setCode('#include<iostream>\nint main(){\n    std::cout<<"Hello World"<<std::endl;\n    return 0;\n}');
              }}
            >
              Preset
            </Button>

            {onSave ? (
              <Button
                onClick={() => {
                  onSave?.(code);
                }}
              >
                Save{`${savedCode?._id?.length || 0 > 0 ? "*" : ""}`}
              </Button>
            ) : null}
            <>{extraButton}</>
          </ButtonGroup>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            style={{ marginLeft: 20 }}
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Options
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                setDebug(!debug);
                appCommand.setNotification({ type: "success", msg: "Debug enabled:" + !debug });
                handleClose();
              }}
            >
              Use Debug Endpoint
            </MenuItem>
            <MenuItem
              onClick={() => {
                // @ts-ignore: Next line is ignored
                setCode(beautify_js.js(code, { indent_size: 2, space_in_empty_paren: true }));
                handleClose();
              }}
            >
              Format
            </MenuItem>
          </Menu>
        </DWeb.DRow>
        <DWeb.DRow style={{ alignItems: "start", borderTop: "1px solid #f1f1f1", paddingTop: 0, marginTop: 10 }}>
          <Editor
            onMount={(e) => {
              editor.current = e;
            }}
            width={"calc(100% - 420px)"}
            height={height || "calc(100vh - 180px)"}
            defaultLanguage="objective-c"
            options={{
              renderLineHighlight: "none",
              formatOnPaste: true,
              formatOnType: true,
              autoIndent: "brackets",
              minimap: {
                enabled: false,
              },
            }}
            defaultValue={code}
            value={code}
            onChange={(data) => {
              if (data) {
                setCode(data);
              }
            }}
          ></Editor>
          <div style={{ flex: "auto", width: 420, paddingLeft: 30, background: "white", marginLeft: 5, height: height || "calc(100vh - 180px)" }}>
            {output ? <DWeb.Html html={`<pre>${output}</pre>`} /> : null}
            {!output ? (
              <DWeb.DText
                style={{
                  color: dcolors.pallets.grey400,
                  justifyContent: "center",
                  alignSelf: "center",
                  fontSize: 20,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                Output would be shown here
              </DWeb.DText>
            ) : null}
          </div>
        </DWeb.DRow>
      </DWeb.DCol>
    );
  }

  // KNOWN BUG AUTO SAVE WNOT WORK AS EXPECTED
  export function RichTextEditor({ onChange, style, onSave, initValue, rightExtraView, onAutoSave }: {
    onSave?: TStringCallback,
    onAutoSave?: TStringCallback, onChange?: TStringCallback, style?: React.CSSProperties, initValue?: string, rightExtraView?: any
  }) {
    const [value, setValue] = useState(initValue || '');
    const isDirtyChange = React.useRef<boolean>(false);

    React.useEffect(() => {
      setValue(initValue || '')
    }, [initValue])


    const handleChange = (content: any, _: any, __: any, editor: any) => {
      setValue(editor.getHTML());
      onChange?.(editor.getHTML())
      isDirtyChange.current = true

    };

    // Implemnet autosave
    // If you chnage something and dont do anything fror 30 sec it will save - this is not the right behavious.
    useEffect(() => {
      const callback = () => {
        if (isDirtyChange.current) {
          dlog.d('autosave trigger')
          // This function must be changed as the value is cached
          onAutoSave?.(value)
          isDirtyChange.current = false

        } else {
          dlog.d('autosave ignored')
        }
      };
      // Set up the timer to call the callback every 30 second
      const intervalId = setInterval(callback, 30 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }, [value]);

    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
    ];

    const modules = {
      toolbar: [
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ font: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        //['link', 'image', 'video'],
        //['clean'],
      ],
    };

    return (
      <DCol style={{ flex: 0, border: '1px solid black', ...style }}>
        <ReactQuill
          modules={modules}
          formats={formats}
          value={value} onChange={handleChange} style={{ border: 'none !important', flex: 1, overflow: 'auto', minHeight: 300, fontFamily: 'monospace', fontSize: 12 }} />
        {onSave ? <DWeb.DRow style={{


          flex: 0, padding: 10, alignItems: 'center'
        }}>
          <DText>{onAutoSave ? 'Auto Save enabled * ' : ''}{value.length} chars</DText>
          <DWeb.DSpace />
          <LoadingButton size="small" onClick={async () => {
            await onSave?.(value);

            isDirtyChange.current = false
          }
          } variant='contained'>{isDirtyChange.current ? '*' : ''}Save</LoadingButton>
          {rightExtraView}
        </DWeb.DRow> : null}
      </DCol>

    );
  }

  /******************************************************
   * Custom Utilities
   *
   *********************************************************/
  export const DTimer = React.memo(() => {
    dlog.d("timer called");
    const ref = React.useRef<any>(null);
    const [timer, setTimer] = React.useState(0);

    const formatMe = () => {
      let seconds = timer % 60;
      let minutes = (timer / 60) % 60;
      let hours = timer / 60 / 60;
      return (hours > 9 ? hours : "0" + hours) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds);
    };

    const startTimer = useCallback((e: any) => {
      if (ref.current) clearInterval(ref.current);
      ref.current = setInterval(() => {
        setTimer(timer + 1);
        dlog.d("" + timer);
      }, 100);
    }, []);
    return (
      <DWeb.DCol>
        <ButtonGroup>
          <Button disabled={false}>{timer}</Button>
          <Button onClick={startTimer}>Reset</Button>
        </ButtonGroup>
      </DWeb.DCol>
    );
  });

  export const PrintData = ({ data }: { data: TObject }) => {
    return <DText style={{ fontFamily: "monospace", fontSize: 13, whiteSpace: "pre" }}>{JSON.stringify(data, null, 2)}</DText>;
  };

  // Dont linke t
  export type DTableColumn = {
    view_type?: "icon_button" | "time" | "link" | "click_to_open" | 'phone' | 'email' | 'multiple_link' | 'link_with_custom_text';
    onClick?: (y: any) => void;
    field: string;
    headerName?: string;
    format?: (arg: string, obj: TObject) => any;
    formatEx?: (arg: string, obj: TObject) => JSX.Element;
    style?: CSSProperties
    styleFn?: (arg: string, obj: TObject) => CSSProperties;
    sortFn?: (a: any, b: any) => number;
    icon?: JSX.Element;// icon_button
  };
  export const DTable = ({
    data,
    columns,
    style,
    actions,
    onRowClicked,
  }: {
    data: TObject[];
    columns: DTableColumn[];
    style?: CSSProperties; // this is a row style
    actions?: { text: string; onClick: TObjCalBack }[];
    onRowClicked?: TObjCalBack;
  }) => {
    const [data1, setdata1] = useState(data)
    React.useEffect(() => setdata1(data), [data])
    return (
      <TableContainer component={Paper} style={{ ...style }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((x, idx) => (
                <TableCell
                  style={{ cursor: x.sortFn ? 'pointer' : 'none' }}
                  key={idx} onClick={() => {
                    if (x.sortFn) {
                      let p = [...data1].sort(x.sortFn)
                      setdata1(p)
                    }
                  }}>{x.headerName || x.field.toUpperCase()} </TableCell>
              ))}
              {actions ? <TableCell style={{ textAlign: "right" }} >Actions</TableCell> : null}
            </TableRow>
          </TableHead>

          <TableBody>
            {data1.map((x, idx) => {
              //
              let actions1 = actions?.map((x1) => ({
                text: x1.text,
                onClick: () => {
                  x1.onClick(x);
                },
              }));

              return (
                <TableRow key={idx} onClick={() => { onRowClicked?.(x) }}>
                  {columns.map((y, idy) => {
                    let d = x[y.field];
                    let style = Object.assign({}, y.style || {}, y.styleFn?.(d, x) || {})


                    if (y.formatEx) {
                      return <TableCell key={idy} style={style}>{y.formatEx(d, x)}</TableCell>;
                    }
                    if (y.format) {
                      d = y.format(d, x);
                    }

                    switch (y.view_type) {
                      case "time":
                        return (
                          <TableCell key={idy} style={style}>
                            <DWeb.DText>{dtime.getAgoString(d)}</DWeb.DText>
                          </TableCell>
                        );
                      case "icon_button":
                        return (
                          <TableCell key={idy} style={style}>
                            <IconButton onClick={() => y.onClick?.(x)}>
                              {y.icon}
                            </IconButton>
                          </TableCell>
                        );
                      case "phone":
                        return (
                          <TableCell key={idy} style={style}>
                            <a href={`tel:` + d} target="_blank">
                              {d}
                            </a>
                          </TableCell>
                        );
                      case "email":
                        return (
                          <TableCell key={idy} style={style}>
                            <a href={`mailto:` + d} target="_blank">
                              {d}
                            </a>
                          </TableCell>
                        );
                      case "link":
                        return (
                          <TableCell key={idy} style={style}>
                            <a href={d} target="_blank">
                              link
                            </a>
                          </TableCell>
                        );
                      case "link_with_custom_text":
                        return (
                          <TableCell key={idy} style={style}>
                            <a href={d[0]} target="_blank">
                              {d[1]}
                            </a>
                          </TableCell>
                        );
                      case "click_to_open":
                        return (
                          <TableCell key={idy} style={style}>
                            <Chip
                              label={"Click to view"}
                              onClick={() => {
                                y?.onClick?.(d);
                              }}
                            />
                          </TableCell>

                        );
                      case "multiple_link":
                        let links = DWebTS.getArray(d) as string[]
                        return (
                          <TableCell
                            style={style}
                            key={idy}>
                            {links.map((yy, idyy) => <Chip key={idyy} style={{ marginRight: 4 }}
                              label={"link #" + (idyy + 1)}
                              onClick={() => {
                                y?.onClick?.(yy);
                              }}
                            />)}

                          </TableCell>
                        );
                      default:
                        return <TableCell style={style} key={idy}>{_.isUndefined(d) || _.isNull(d) ? "-" : d + ""}</TableCell>;
                    }
                  })}
                  {actions ? (
                    <TableCell style={{ textAlign: "right" }}>
                      <DWeb.DropDownMenu actions={actions1 || []} />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data?.length == 0 ? <DText style={{ flex: 1, textAlign: "center", padding: 15 }}> No data found</DText> : null}
      </TableContainer>
    );
  };

  export const NotFound = ({ info }: { info?: TBasicInfo }) => {
    return (
      <ResponsiveWindow>
        <DWeb.DCol style={{ alignItems: "center", width: 250, margin: "0 auto" }}>
          <HeartBrokenRoundedIcon style={{ fontSize: 80, margin: 20, textAlign: "center", color: dcolors.pallets.grey500 }} />
          <DWeb.DText style={{ textAlign: "center", fontSize: 20, color: dcolors.pallets.grey500 }}> {info?.title || 'PAGE NOT FOUND'}</DWeb.DText>
          <DWeb.DText style={{ textAlign: "center", fontSize: 14, color: dcolors.pallets.grey600, marginTop: 10 }}>
            {info?.subtitle || 'The page you are looking for is not exist. Please check your url.'}
          </DWeb.DText>
          {info?.extra}
        </DWeb.DCol>
      </ResponsiveWindow>
    );
  };

  export function CircularProgressWithLabel(props: any) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"

          value={100}
          size={props.size}
          sx={{
            color: dcolors.pallets.grey100,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <CircularProgress
          variant="determinate"

          {...props}
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary" style={{ fontSize: 20, fontWeight: "bold" }}>
            {(props.value as number).toFixed(2)}%
          </Typography>
        </Box>
      </Box>
    );
  }

  export function DashboardFig(props: any) {
    return (
      <DWeb.DCol style={{ position: "relative" }}>
        <DWeb.DCol style={{ alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            <DWeb.CircularProgressWithLabel value={props.value || 0} variant="determinate" size={120} />
          </div>
          <DWeb.DText style={{ marginTop: 5, fontWeight: "bold" }}>{props.label}</DWeb.DText>
          {props.subtext ? (
            <DWeb.DText style={{ marginTop: 5, textAlign: "center", maxWidth: 200, color: dcolors.pallets.grey500 }}>{props.subtext}</DWeb.DText>
          ) : null}
        </DWeb.DCol>
      </DWeb.DCol>
    );
  }

  /******************************************************
   * Define App Layout and Navigation
   *
   *********************************************************/

  export const ResponsiveWindow = ({ children, width, style, boxed, className }: { children?: any; width?: number; style?: CSSProperties; boxed?: boolean, className?: string }) => {
    return (
      <Box
        className={className}
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flex: 1,
          boxSizing: "border-box",
          alignItems: "center",
          maxWidth: "100%",
          width: {
            xs: "calc(100% - 8px)", // theme.breakpoints.up('xs')
            sm: "calc(100% - 40px)", // theme.breakpoints.up('sm')
            md: width || 900, // theme.breakpoints.up('md') //>900
            lg: width || 900, // theme.breakpoints.up('lg') // 900
            xl: width || 1200, // theme.breakpoints.up('xl') // 1536
          },
          margin: {
            xs: "0 4px", // theme.breakpoints.up('xs')
            sm: "0 20px", // theme.breakpoints.up('sm')
            md: "0 auto", // theme.breakpoints.up('md')
            lg: "0 auto", // theme.breakpoints.up('lg')
            xl: "0 auto", // theme.breakpoints.up('xl')
          },
        }}
      >
        {boxed ? (
          <DWeb.DBox style={{ margin: 20, width: "100%", justifyItems: "center", alignItems: "center", padding: 20, ...style }}>{children}</DWeb.DBox>
        ) : (
          <DWeb.DCol style={{ width: "100%", ...style }}>{children || null}</DWeb.DCol>
        )}
      </Box>
    );
  };

  export type TNavigationItem = {
    text: string;
    onClick?: TVoidCalBack;
    icon?: any;
    subtext?: string;
    href?: string;
    showAfterLogin?: boolean;
  };

  export type TNestedNavigation = Array<{
    title: string;
    path?: string;
    screen?: JSX.Element;
    screenFn?: () => JSX.Element;
    icon?: JSX.Element;
    child?: { title: string; path?: string; screen?: JSX.Element; screenFn?: () => JSX.Element; icon?: JSX.Element }[];
  }>;

  // Core App Layout and Navigation
  export type TDPageRootLayoutConfig = {
    title: string;
    title_path?: string
    settingNavList?: { text: string; link: string }[];
    login?: boolean;
    drawerWidth?: number;
    logo?: any;
    icon?: any;
    rightActions?: TNavigationItem[]; // showing list of action in right size.
    appBarTrasparent?: boolean;
    appBarTextStyle?: CSSProperties;
    appBarStyle?: CSSProperties;
    hideFooter?: Boolean;
    topNavigation?: TNestedNavigation;
    appBarConfig?: {
      appBarRightJSX?: JSX.Element;
    };
  };

  export function getAllRoute(nav?: TNestedNavigation): JSX.Element {
    return (
      <>
        {nav?.map((x) => {
          return (
            <>
              {x.child?.map((y) => {
                dlog.d("registering path:" + x.path + y.path);
                if (y.screen) {
                  return <Route path={"" + x.path + y.path} element={y.screen} />;
                } else {
                  return <Route path={"" + x.path + y.path} element={y.screenFn?.()} />;
                  //return <Route path={x.path + y.path}></Route>;
                }
              })}
            </>
          );
        })}
      </>
    );
  }

  // should not be used externally
  /*export*/ const DAppParentWrapper = ({ children }: { children: any }) => {
    const appCommand = DWeb.useAppCommand();
    return (
      <div>
        {appCommand.isDebugMode() ? (
          <DText
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              color: "white",
              background: dcolors.pallets.red600,
              zIndex: 10000,
              fontSize: 10,
              textAlign: "center",
            }}
          >
            You are running in debug mode
          </DText>
        ) : null}
        <div>{children}</div>
      </div>
    );
  };

  // option 1: Only Top Navigation Any Page from this project must be wrap around this.
  export const DPageRootLayout = ({
    children,
    style,
    config,
    leftElement,
    navLeftOffset,
  }: {
    children: any;
    style?: CSSProperties;
    config: TDPageRootLayoutConfig;
    leftElement?: any;
    navLeftOffset?: number;
  }) => {
    const appCommand = DWeb.useAppCommand();
    if (config.login && !appCommand.accountState) {
      return (
        <DAppParentWrapper>
          <DWeb.ResponsiveWindow>
            <Alert severity="error" style={{ marginTop: 30, minHeight: "100vh" }}>
              <AlertTitle>You are not logged in.</AlertTitle>
              Please fist login and then you should able to view this page. <a href="/account">click</a> to login here.
            </Alert>
          </DWeb.ResponsiveWindow>
        </DAppParentWrapper>
      );
    }
    return (
      <DAppParentWrapper>
        <DWeb.DCol style={{ flex: 1, position: "relative" }}>
          <DNavigation leftElement={leftElement} config={config} leftOffset={navLeftOffset} />
          <DWeb.DCol style={{ paddingLeft: 10, paddingRight: 10, ...style, paddingTop: 46, minHeight: "100vh" }}>
            <DCol style={{ position: "relative" }}>{children}</DCol>
            {!config.hideFooter ? <DFooter /> : null}
          </DWeb.DCol>
        </DWeb.DCol>
      </DAppParentWrapper>
    );
  };

  // Option 2: top and side navigation App Root with Top Navigation Bar + Drawer
  export const DPageRootLayoutWithDrawer = React.memo(({
    config,
    children,
    drawerContent,
    drawerOpen,
    drawerPreset,
  }: {
    config: TDPageRootLayoutConfig;
    children?: any;
    drawerContent?: any;
    drawerOpen?: boolean;
    drawerPreset?: {
      drawerWidth?: number;
      headerText?: String;
      footerText?: String;
      drawerNestedNavigation?: TNestedNavigation;
      onSettingClicked?: TVoidCalBack;
      drawerBackground?: string;
      color?: string;
      menuList?: {
        icon?: ReactNode;
        shortcuts?: ReactNode;
        name: string;
        onClick: TVoidCalBack;
      }[];
    };
  }) => {
    const appCommand = useAppCommand()
    const isTablet = useMediaQuery("(max-width: 35rem");
    let KdrawerWidth = isTablet ? window.innerWidth : drawerPreset?.drawerWidth || config?.drawerWidth || 350;
    const [open, setOpen] = React.useState(drawerOpen || false);
    const [holderNode, setHolderNode] = useState<JSX.Element | undefined>(children);
    const [drawerWidth, setDrawerWidth] = React.useState(0);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [selected, setSelected] = React.useState('')

    if (appCommand.isDebugMode()) {
      _.extend(drawerPreset?.drawerNestedNavigation, DrawerNestedNavigationDebugOnly);
    }


    // TODO: THIS IS A Conclict
    // LITHUK needs this as as the child should be changing 
    // TODO: This is causing issue while chnageing anything will effect navigation 
    React.useEffect(() => {
      setHolderNode(children)
    }, [children])

    React.useEffect(() => {
      setDrawerWidth(open ? KdrawerWidth : 0);
    }, [open]);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    let drawerStyle: CSSProperties = { color: drawerPreset?.color || "black", background: drawerPreset?.drawerBackground || "white" };
    return (
      <DWeb.DCol>
        <DPageRootLayout
          config={config}
          navLeftOffset={drawerWidth}
          leftElement={
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }} onClick={() => setOpen(!open)}>
              {<MenuIcon style={{ ...config?.appBarTextStyle }} />}
            </IconButton>
          }
          style={{
            marginLeft: open ? drawerWidth : 0,
            width: open ? `calc( 100% - ${drawerWidth}px)` : "100%",
          }}
        >
          {holderNode || <DWeb.PageInConstruction />}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              ...drawerStyle,
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {drawerContent}
            {drawerPreset ? (
              <DWeb.DCol style={{ ...drawerStyle }}>
                {drawerPreset.headerText ? (
                  <DText mode="title" style={{ ...drawerStyle, borderBottom: "1px solid grey", padding: 16, textAlign: "left" }}>
                    {drawerPreset.headerText}
                  </DText>
                ) : null}
                <MenuList>
                  {drawerPreset.menuList?.map((x, idx) => {
                    return (
                      <MenuItem key={idx + ""} onClick={x.onClick}>
                        {x.icon ? <ListItemIcon style={{ ...drawerStyle }}>{x.icon}</ListItemIcon> : null}
                        <ListItemText style={{ ...drawerStyle }}>{x.name}</ListItemText>
                        <Typography variant="body2" style={{ ...drawerStyle }}>
                          {x.shortcuts}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </MenuList>

                {drawerPreset?.drawerNestedNavigation
                  ? drawerPreset.drawerNestedNavigation.map((x, idx) => {
                    if (!x.child) {
                      return (
                        <Button
                          key={idx}
                          style={{
                            color: drawerStyle.color,
                            justifyContent: "left",
                            textTransform: "capitalize",
                            fontSize: 16,
                            paddingLeft: 20,
                            fontWeight: selected == `${idx}` ? 'bold' : 'normal',
                            backgroundColor: selected == `${idx}` ? dcolors.pallets.blue50 : dcolors.pallets.transparent
                          }}
                          href={x.path}
                          onClick={() => {
                            setSelected(`${idx}`)
                            setHolderNode(x.screen);
                          }}
                          startIcon={x.icon}
                        >
                          {x.title}
                        </Button>
                      );
                    }

                    return (
                      <Accordion
                        key={idx}
                        disableGutters={true}
                        expanded={expanded === x.title}
                        onChange={handleChange(x.title)}
                        style={{ background: "#00000000", boxShadow: "none", width: '100%', border: 0 }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: drawerStyle.color }} />} style={{ margin: 0, padding: 0, border: 0, flex: 1, width: '100%' }}>
                          <Button
                            key={idx}
                            style={{
                              color: drawerStyle.color,
                              justifyContent: "left",
                              textTransform: "capitalize",
                              fontSize: 16,
                              width: "100%",
                              paddingLeft: 20,
                              fontWeight: selected == `${idx}` ? 'bold' : 'normal',
                              backgroundColor: selected == `${idx}` ? dcolors.pallets.blue50 : dcolors.pallets.transparent
                            }}
                            href={x.path}
                            onClick={() => {
                              setSelected(`${idx}`)
                              setHolderNode(x.screen);
                            }}
                            startIcon={x.icon}
                          >
                            <DWeb.DText>{x.title}</DWeb.DText>
                          </Button>

                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, paddingLeft: 0 }}>
                          <DWeb.DCol>
                            {x.child?.map((y, idy) => {
                              return (
                                <Button
                                  key={idy}
                                  style={{
                                    color: drawerStyle.color,
                                    justifyContent: "left",
                                    textTransform: "capitalize",
                                    marginLeft: 20,
                                    fontSize: 16,
                                    fontWeight: selected == `${idx}.${idy}` ? 'bold' : 'normal',
                                    backgroundColor: selected == `${idx}.${idy}` ? dcolors.pallets.blue50 : dcolors.pallets.transparent
                                  }}
                                  startIcon={y.icon}
                                  href={y.path ? x.path + y.path : undefined}
                                  onClick={() => {
                                    setSelected(`${idx}.${idy}`)
                                    setHolderNode(y.screen);
                                  }}
                                >
                                  {y.title}
                                </Button>
                              );
                            })}
                          </DWeb.DCol>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                  : null}
                <DSpace />
                {drawerPreset.footerText ? (
                  <DRow style={{ borderTop: "1px solid grey", padding: 20, flex: 0, alignItems: "center" }}>
                    <DText style={{ flex: 1, color: drawerStyle.color }}>{drawerPreset.footerText}</DText>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2, color: drawerStyle.color }}
                      onClick={drawerPreset.onSettingClicked}
                    >
                      <SettingIcon />
                    </IconButton>
                  </DRow>
                ) : null}
              </DWeb.DCol>
            ) : null}
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              style={{ position: "absolute", top: 12, right: 10 }}
            >
              <CloseIcon style={{ color: drawerStyle.color }} />
            </IconButton>
          </Drawer>
        </DPageRootLayout>
      </DWeb.DCol>
    );
  });

  // Implementing nested flooting menu
  export const DNestedMenu = ({
    menu,
  }: {
    menu: { title?: string; path?: string; child?: { title: string; path?: string; onClick?: TVoidCalBack }[]; icon?: any; onClick?: TVoidCalBack };
  }) => {
    // we need mutiple anchorEl for each nested menu
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const navigate = useNavigate();
    const handleMenuClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    if (!menu.child) {
      return <Button onClick={menu.onClick}>{menu.title}</Button>;
    }

    return (
      <div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={() => {
            menu.onClick?.();
          }}
        >
          {menu.child?.map((x, idx) => (
            <MenuItem
              onClick={() => {
                dlog.d("Test")
                handleMenuClose();
                if (!menu.path && x.path) {
                  navigate(x.path)
                }
                if (menu.path && x.path) {
                  navigate(menu.path + x.path);
                }
                x.onClick?.();
              }}
              key={idx}
            >
              {x.title}
            </MenuItem>
          ))}
        </Menu>
        {menu.title ? (
          <Button onClick={handleMenuClick} style={{ color: "white", textTransform: "capitalize" }}>
            {menu.title}
          </Button>
        ) : null}
        {menu.icon ? <IconButton onClick={handleMenuClick}>{menu.icon}</IconButton> : null}
      </div>
    );
  };

  // Top Navigation Bar
  export const DNavigation = ({ config, leftElement, leftOffset }: { config: TDPageRootLayoutConfig; leftElement?: any; leftOffset?: number }) => {
    const appCommand = DWeb.useAppCommand();
    //const courseCommand = useCourse();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const handleMenuClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    let style: CSSProperties = { backgroundColor: appCommand.getAppConfig()?.primary_color!!, ...config.appBarStyle };
    if (config.appBarTrasparent) {
      style = { background: "transparent", boxShadow: "none", color: "black", ...style };
    }
    return (
      <AppBar style={{ position: "fixed", left: leftOffset || 0, maxWidth: `calc( 100% - ${leftOffset || 0}px)`, ...style }}>
        <Toolbar variant="dense" style={{ alignItems: "center", display: "flex" }}>
          {leftElement}
          {config.logo ? (
            <a href="/">
              <img src={config.logo} style={{ width: 23, height: 23, marginRight: 10 }} />
            </a>
          ) : null}
          {config.icon ? <a href="/">{config.icon}</a> : null}
          <Typography variant="h6" component="div" sx={{ ...config.appBarTextStyle, marginRight: 5 }} >
            <a href={config.title_path} style={{ textDecoration: 'none', color: 'inherit' }}>{config.title}</a>
          </Typography>

          {config.topNavigation
            ? config.topNavigation.map((x, idx) => {
              if ((x.child?.length || 0) > 0) {
                return <DNestedMenu menu={x} key={idx} />;
              }
              return <Button href={x.path} key={idx} style={{ color: 'white', textTransform: 'none' }}>{x.title}</Button>;
            })
            : null}
          <DWeb.DSpace />
          {config.rightActions?.map((x, idx) => {
            if (x.showAfterLogin && !appCommand.accountState) {
              return null;
            }
            return x.text ? (
              <Button onClick={x.onClick} key={idx} style={{ textTransform: "capitalize", ...config.appBarTextStyle }} href={x?.href}>
                {x.text}
              </Button>
            ) : (
              <IconButton key={idx} size="large" aria-label="search" color="inherit" onClick={x.onClick}>
                {x.icon}
              </IconButton>
            );
          })}

          {appCommand.accountState ? (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              {config.appBarConfig?.appBarRightJSX || null}
              <IconButton
                size="large"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                href={config.settingNavList ? "" : "/account"}
                color="inherit"
              >
                <Avatar sx={{ width: 24, height: 24 }} alt={appCommand.accountState.name} src={appCommand.accountState.image} />
                {config.settingNavList ? <KeyboardArrowDownIcon style={{ marginLeft: 10 }} /> : null}
              </IconButton>
              {config.settingNavList ? (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                >
                  {config.settingNavList?.map((k, idk) => {
                    return (
                      <MenuItem
                        key={idk}
                        onClick={() => {
                          appCommand.navigate(k.link);
                        }}
                      >
                        {k.text}
                      </MenuItem>
                    );
                  })}
                </Menu>
              ) : null}
            </div>
          ) : (
            <Button style={{ textTransform: "capitalize", ...config.appBarTextStyle }} href={"/account"}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  // Define the page Not found UI
  export const PageNotFound = () => {
    return (
      <DAppWrapper>
        <DWeb.NotFound />
      </DAppWrapper>
    );
  };

  export const PageInConstruction = () => {
    return (
      <PageFullInformation
        icon={<img src={require("./img/error.png")}></img>}
        title="This feature is under construction!"
        subtitle="Hold on! We are working on this feature. Please revisit this page after sometime."
      />
    );
  };

  export const PageFullInformation = ({ style, icon, title, subtitle, extra_comp }: { icon?: any; title?: string; subtitle?: string; extra_comp?: any, style?: CSSProperties }) => {
    return (
      <DCenter style={style}>
        {icon}
        <DText mode="title" style={{ marginBottom: 20, width: 440, color: dcolors.pallets.black, fontSize: 32, lineHeight: 1.2 }}>
          {title}
        </DText>
        <DText mode="subtitle" style={{ marginBottom: 20, width: 450, lineHeight: 1 }}>
          {subtitle}
        </DText>
        {extra_comp}
      </DCenter>
    );
  };

  // Define common FooterBar
  export const DFooter = () => {
    let appCommand = useAppCommand();
    return (
      <DWeb.DCol style={{ padding: 10, flex: 0, alignItems: 'center', background: '#0000000010' }}>
        <DWeb.DRow style={{ justifyContent: "center", alignItems: 'center' }}>
          {appCommand.getAppConfig()?.social?.email ? (
            <IconButton href={appCommand.getAppConfig()!!.social!!.email!!} target="_blank">
              <EmailIcon style={{ margin: 10 }} />
            </IconButton>
          ) : null}

          {appCommand.getAppConfig()?.social?.whatsapp ? (
            <IconButton href={appCommand.getAppConfig()!!.social!!.whatsapp!!} target="_blank">
              <WhatsAppIcon style={{ margin: 10 }} />
            </IconButton>
          ) : null}
          {appCommand.getAppConfig()?.social?.fb_page ? (
            <IconButton href={appCommand.getAppConfig()!!.social!!.fb_page!!} target="_blank">
              <FacebookIcon style={{ margin: 10 }} />
            </IconButton>
          ) : null}
          {appCommand.getAppConfig()?.social?.linkedin ? (
            <IconButton href={appCommand.getAppConfig()!!.social!!.linkedin!!} target="_blank">
              <LinkedInIcon style={{ margin: 10 }} />
            </IconButton>
          ) : null}
          {appCommand.getAppConfig()?.social?.release_note ? (
            <IconButton href={appCommand.getAppConfig()!!.social!!.release_note!!} target="_blank">
              <HelpOutline style={{ margin: 10 }} />
            </IconButton>
          ) : null}


          <DWeb.DFormDialog
            model={{
              data: [
                { type: "input", name: "title", placeholder: "Please enter the title" },
                { type: "textarea", name: "description", placeholder: "Please enter the description" },
              ],
            }}
            onSubmit={async (obj: TObject) => {
              await dnetwork.postSimpleStore("https://simplestore.dipankar.co.in/api/remote_feedback/create", obj);
              return "feedback submitted";
            }}
            dialogConfig={{
              title: "Please provide feedback for this website",
              customTrigger: <BugReportOutlined style={{ marginLeft: 10, cursor: "pointer" }} />,
            }}
          />
        </DWeb.DRow>
        <DWeb.DRow style={{ flex: 0, alignSelf: "center" }}>
          <Typography style={{ color: dcolors.pallets.grey800, textAlign: "center", fontSize: 12 }}>© Copyright DSoftware Pvt Ltd 2022 | Last build:{buildConfig.date}</Typography>
        </DWeb.DRow>
      </DWeb.DCol>
    );
  };
  /*********************************************************
   * Define Forms
   *
   *********************************************************/
  export type DFormSupportedType =
    | "date"
    | "time"
    | "datetime"
    | "input"
    | "textarea"
    | "radio"
    | "checkbox"
    | "switch"
    | "hidden"
    | "number"
    | "password"
    | "select"
    | "file"
    | 'files'
    | "image";
  export type TOption = {
    key: string;
    value: any;
    text: string;
  };

  // Define Forms
  export type DFormData = {
    name: string;
    label?: string;
    type: DFormSupportedType;
    subtype?: "password" | "email" | undefined;
    default?: any;
    required?: boolean;
    value?: any;
    disabled?: boolean;
    placeholder?: string;
    options?: TOption[];
    style?: CSSProperties;
  };

  export type DFormStepData = {
    label: string;
    data: Array<DFormData>;
  }[];

  export type TFormDataModel = { data?: DFormData[]; stepData?: DFormStepData };

  function isMissing(x: any) {
    if (x == null || x == undefined) {
      return true;
    }
    if (x.length == 0) {
      return true;
    }
    return false;
  }

  export const DForm = React.memo(
    ({
      model,
      prefill,
      onSubmit,
      onCancel,
      config,
    }: {
      model: TFormDataModel;
      prefill?: TObject;
      onSubmit: (data: TObject) => void;
      onCancel?: TVoidCalBack;
      config?: TFormConfig;
    }) => {
      let [count, setCount] = useState(0)
      function getAllField(): DFormData[] {
        if (model.data) {
          return model.data;
        }
        if (model.stepData) {
          let result: DFormData[] = [];
          model.stepData.forEach((x) => {
            x.data.forEach((y) => {
              result.push(y);
            });
          });
          return result;
        }
        return [];
      }

      // populate value
      React.useEffect(() => {
        let result1: TObject = {};
        let map: { [key: string]: DFormData } = {};

        getAllField().forEach((x: DFormData) => {
          result1[x.name] = "";
          if (!isMissing(prefill?.[x.name])) {
            result1[x.name] = prefill?.[x.name];
          }

          if (!isMissing(x.value)) {
            result1[x.name] = x.value;
          }
          if (!isMissing(x.default)) {
            result1[x.name] = x.default;
          }

          map[x.name] = x;

          // fixTypeIn Inplace:
          if (map[x.name].type == "date") {
            // Do Nothing as working expected
          }
        });
        setResult(result1);
        setModel1(map);
      }, [model.data, model.stepData, count, prefill]);

      let [disableEdit1, setDisableEdit1] = React.useState(config?.disableEdit || false)

      let [model1, setModel1] = React.useState<{ [key: string]: DFormData }>({});
      let [result, setResult] = React.useState<TObject>({});
      // mainly used for files
      let [loading, setLoading] = React.useState(false);
      let [error, setError] = React.useState("");
      const [activeStep, setActiveStep] = React.useState(0);

      const setData = (key: string, value?: any) => {
        let l: TObject = {};
        l[key] = value;
        setResult({ ...result, ...l });
      };

      const handleSubmit = async () => {
        if (!validateData()) {
          return;
        }
        setLoading(true);

        try {
          await onSubmit(fixTypeOut(result));
          // this will clear the form
          if (!config?.noClearOnSubmit) {
            setCount(count + 1)
          }
        } catch (e: any) {
          setError(e.message);
        }
        setLoading(false);
      };

      const check = (key: string) => {
        if (model1[key].required == true && (result[key] == undefined || result[key].length == 0)) {
          throw Error(`Missing ${model1[key].name}. Please fill this`);
        }
      };

      // make sure all data is valid.
      const validateData = () => {
        try {
          getAllField().forEach((x) => {
            check(x.name);
          });
          return true;
        } catch (e: any) {
          setError(e.message);
          return false;
        }
      };

      // This will fix the types like example number type should be a number
      const fixTypeOut = (obj: TObject) => {
        let newObj = DWebTS.deepCopy(obj);
        Object.keys(newObj).map((key) => {
          if (model1[key].type == "number") {
            newObj[key] = parseFloat(newObj[key]);
          }
          if (model1[key].type == "date") {
            // Ensure this is IOS format - Verified manullay
            newObj[key] = newObj[key];
          }
        });
        return newObj;
      };

      // Make sure what is filled up in result is valid,
      const validatePartialData = () => {
        try {
          model.stepData?.[activeStep].data.forEach((x) => {
            check(x.name);
          });
          return true;
        } catch (e: any) {
          setError(e.message);
          return false;
        }
      };

      function renderSwitch(data: DFormData) {
        switch (data.type) {
          default:
          case "input":
            return (
              <TextField
                required={data.required}
                disabled={data.disabled}
                placeholder={data.placeholder}
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                value={result[data.name]}
                onChange={(e) => setData(data.name, e.target.value)}
              />
            );
          case "hidden":
            return (
              <TextField
                required={data.required}
                disabled={data.disabled}
                placeholder={data.placeholder}
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                value={result[data.name]}
                onChange={(e) => setData(data.name, e.target.value)}
              />
            );
          case "number":
            return (
              <TextField
                placeholder={data.placeholder}
                required={data.required}
                disabled={data.disabled}
                type="number"
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                value={result[data.name]}
                onChange={(e) => setData(data.name, e.target.value)}
              />
            );
          case "password":
            return (
              <TextField
                placeholder={data.placeholder}
                required={data.required}
                disabled={data.disabled}
                type="password"
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                value={result[data.name]}
                onChange={(e) => setData(data.name, e.target.value)}
              />
            );
          case "textarea":
            return (
              <TextField
                placeholder={data.placeholder}
                required={data.required}
                disabled={data.disabled}
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                minRows={4}
                multiline={true}
                value={result[data.name]}
                onChange={(e) => setData(data.name, e.target.value)}
              />
            );
          case "date":
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  readOnly={data.disabled}
                  label={data.label || data.name.toUpperCase()}
                  inputFormat="DD/MM/YYYY"
                  value={result[data.name]}
                  onChange={(value: any) => {
                    setData(data.name, value);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} required={data.required} disabled={data.disabled} placeholder={data.placeholder} />
                  )}
                />
              </LocalizationProvider>
            );
          case "time":
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  readOnly={data.disabled}
                  label={data.label || data.name.toUpperCase()}
                  value={result[data.name]}
                  onChange={(value: any) => {
                    setData(data.name, value);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} required={data.required} disabled={data.disabled} placeholder={data.placeholder} />
                  )}
                />
              </LocalizationProvider>
            );
          case "datetime":
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  readOnly={data.disabled}
                  label={data.label || data.name.toUpperCase()}
                  value={result[data.name]}
                  onChange={(value: any) => {
                    setData(data.name, value);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} required={data.required} disabled={data.disabled} placeholder={data.placeholder} />
                  )}
                />
              </LocalizationProvider>
            );
          case "select":
            return (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{data.label || data.name.toUpperCase()}</InputLabel>
                <Select
                  placeholder={data.placeholder}
                  required={data.required}
                  disabled={data.disabled}
                  label={data.label || data.name.toUpperCase()}
                  value={result[data.name] || ""}
                  onChange={(e) => setData(data.name, e.target.value)}
                >
                  {data.options?.map((op) => (
                    <MenuItem key={op.key} value={op.value}>
                      {op.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          case "switch":
            return (
              <FormControlLabel
                disabled={data.disabled}
                control={
                  <Switch
                    placeholder={data.placeholder}
                    required={data.required}
                    //label={data.label || data.name.toUpperCase()}
                    value={result[data.name] || false}
                    onChange={(e) => setData(data.name, e.target.checked)}
                    disabled={data.disabled}
                  />
                }
                label={data.label || data.name.toUpperCase()}
              />
            );
          case "image":
            return (
              <TextField
                placeholder={data.placeholder}
                required={data.required}
                disabled={data.disabled}
                type="file"
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                onChange={(e) => setData(data.name, (e.target as HTMLInputElement).files?.[0])}
              />
            );
          case "file":
            return (
              <TextField
                placeholder={data.placeholder}
                required={data.required}
                disabled={data.disabled}
                type="file"
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                onChange={(e) => setData(data.name, (e.target as HTMLInputElement).files?.[0])}
              />
            );
          case "files":
            return (
              <TextField
                placeholder={data.placeholder}
                required={data.required}
                disabled={data.disabled}
                type="file"
                label={data.label || data.name.toUpperCase()}
                variant="outlined"
                inputProps={{
                  multiple: true
                }}
                onChange={(e) => {
                  let test = (e.target as HTMLInputElement).files as any
                  let files = []
                  for (let i = 0; i < test.length; i++) {
                    files.push(test[i])
                  }
                  setData(data.name, files)
                }}
              />
            );
        }
      }

      return (
        <DCol>
          <DWeb.DRow style={{ marginBottom: 20 }}>
            <DText mode="title" style={{ flex: 1 }}>{config?.title || 'Form'}</DText>
            {disableEdit1 ? <Button variant="outlined" onClick={() => setDisableEdit1(false)}>Edit</Button> : null}
          </DWeb.DRow>
          {error && error.trim().length > 0 ? (
            <Alert severity="error" style={{ marginBottom: 20 }}>
              {error}
            </Alert>
          ) : null}
          {model.data ? (
            <>
              <div style={{ position: 'relative' }}>
                <DWeb.DRow style={{ flexWrap: "wrap" }}>
                  {model.data.map((x, idx) => {
                    // normal Form
                    return (
                      <DCol
                        key={idx}
                        style={{ marginBottom: 20, flex: "none", width: "100%", ...x.style, display: x.type == "hidden" ? "none" : "flex" }}
                      >
                        {renderSwitch(x)}
                      </DCol>
                    );
                  })}

                </DWeb.DRow>
                {disableEdit1 ? <AbsoluteDiv /> : null}
              </div>
              {!disableEdit1 ? (<DRow style={{ marginTop: 30, flex: "none", width: "100%" }}>
                <DSpace />
                <Button onClick={() => { setDisableEdit1(true); onCancel?.() }} style={{ marginRight: 20 }}>
                  Cancel
                </Button>
                <LoadingButton onClick={handleSubmit} variant="contained" loading={loading}>
                  {config?.trigger_btn_text || " Submit"}
                </LoadingButton>
              </DRow>) : null}
            </>
          ) : null}

          {model.stepData ? (
            <>
              <Stepper alternativeLabel activeStep={activeStep} style={{ marginBottom: 20 }}>
                {model.stepData.map((x) => (
                  <Step key={x.label}>
                    <StepLabel>{x.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {model.stepData.map((x, idx) => (
                <DWeb.DCol style={{ display: activeStep === idx ? "flex" : "none" }}>
                  <DWeb.DRow style={{ flexWrap: "wrap" }}>
                    {x.data.map((y) => (
                      <DCol
                        key={y.name}
                        style={{ marginBottom: 20, flex: "none", width: "100%", ...y.style, display: y.type == "hidden" ? "none" : "flex" }}
                      >
                        {renderSwitch(y)}
                      </DCol>
                    ))}
                  </DWeb.DRow>
                </DWeb.DCol>
              ))}
              <DWeb.DRow>
                {activeStep > 0 ? (
                  <Button onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)} variant="contained">
                    BACK
                  </Button>
                ) : null}
                <DSpace />
                {activeStep < model.stepData.length - 1 ? (
                  <Button onClick={() => (validatePartialData() ? setActiveStep((prevActiveStep) => prevActiveStep + 1) : null)} variant="contained">
                    Next
                  </Button>
                ) : null}
                {activeStep == model.stepData.length - 1 ? (
                  <Button onClick={() => { onCancel?.() }} style={{ marginRight: 10 }}>
                    Cancel
                  </Button>
                ) : null}
                {activeStep == model.stepData.length - 1 ? (
                  <Button onClick={handleSubmit} variant="contained">
                    Submit
                  </Button>
                ) : null}
              </DWeb.DRow>
            </>
          ) : null}
        </DCol>
      );
    }
  );

  export type TFormConfig = {
    title: string; // dialog title
    trigger_btn_text?: string;
    triggerStyle?: CSSProperties;
    triggerIcon?: React.ReactNode;
    customTrigger?: React.ReactNode;
    noClearOnSubmit?: boolean; // by defaukt it will clear the inpit
    submit_btn_text?: boolean;
    disableEdit?: boolean;// first show in read only mode.
  };

  export const DFormDialog = React.memo(
    ({
      model,
      prefill,
      onSubmit,
      onCancel,
      dialogConfig,
      visible,
    }: {
      model: TFormDataModel;
      prefill?: TObject;
      onSubmit: (data: TObject, pendingResult?: TObject) => void;
      onCancel?: TVoidCalBack;
      dialogConfig: TFormConfig;
      visible?: boolean;
    }) => {

      React.useEffect(() => {
        setOpen(visible || false)
      }, [visible])

      const [open, setOpen] = React.useState(visible || false);
      const handleClose = () => {
        setOpen(false);
        onCancel?.();
      };
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {/* This for the trigger */}
          {dialogConfig.customTrigger ? <span onClick={() => setOpen(true)}>{dialogConfig.customTrigger}</span> : null}
          {dialogConfig.trigger_btn_text ? (
            <Button onClick={() => setOpen(true)} style={{ ...dialogConfig.triggerStyle, flex: 0 }} startIcon={dialogConfig.triggerIcon} variant="contained" >
              {dialogConfig.trigger_btn_text}
            </Button>
          ) : null}
          {dialogConfig.triggerIcon ? (
            <IconButton onClick={() => setOpen(true)} style={dialogConfig.triggerStyle}>
              {dialogConfig.triggerIcon}
            </IconButton>
          ) : null}

          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            //scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">{dialogConfig.title}</DialogTitle>
            <DialogContent dividers={true}>
              <DForm
                model={model}
                prefill={prefill}
                onSubmit={async (data) => {
                  await onSubmit(data);
                  handleClose();
                }}
                onCancel={() => {
                  onCancel?.();
                  handleClose();
                }}
                config={dialogConfig}
              />
            </DialogContent>
          </Dialog>
        </div>
      );
    }
  );

  /*********************************************************
   * Define CRM for CRUD
   *********************************************************/

  export type DCRMActions = {
    onCreate?: (obj: TObject, pendingObj?: TObject) => void;
    onSearch?: (obj?: TObject | string) => Promise<TObject[]>;
    onDelete?: (_id: string) => void;
    onUpdate?: (_id: string, obj: TObject) => void;
  };
  // you should eb using memo
  export const DCrmCRUD = ({
    model,
    actions,
    config,
  }: {
    model: TFormDataModel;
    actions: DCRMActions;
    config?: {
      disable_debug?: boolean; // this must be true by default
      title?: string;
      name: string;
      tableViewColumns?: DTableColumn[];
      rowView?: (obj: TObject, idx: number, actions: DCRMActions) => JSX.Element;
    };
  }) => {
    const [result, setResult] = React.useState<TObject[]>([]);
    const [query, setQuery] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    DWebTS.useEffectAsync(async () => {
      reload();
    }, []);
    const _actions: DCRMActions = {
      onCreate: async (obj: TObject) => {
        await actions.onCreate?.(obj);
        await reload();
      },
      onDelete: async (_id: string) => {
        await actions.onDelete?.(_id);
        await reload();
      },
      onSearch: actions.onSearch,
      onUpdate: async (_id: string, obj: TObject) => {
        await actions.onUpdate?.(_id, obj);
        await reload();
      },
    };

    const reload = async () => {
      try {
        setLoading(true);
        const data = (await actions?.onSearch?.(query)) || [];
        setResult(data);
      } catch (e) {
        // todo
      }
      setLoading(false);
    };

    return (
      <DCol>
        <DRow style={{ paddingTop: 20, paddingBottom: 20 }}>
          <DText mode="title">{config?.name}</DText>
          <TextField
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            style={{ marginLeft: 30, flex: 1, marginRight: 30 }}
            size="small"
            placeholder="Write something to search"
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <InputAdornment position="end">
                      <IconButton onClick={reload}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
            onKeyPress={(event) => {
              if (event.code == "Enter") {
                reload();
              }
            }}
            variant="outlined"
          />

          <DWeb.DFormDialog
            model={model}
            onSubmit={async (obj, pendingObj) => {
              await actions.onCreate?.(obj, pendingObj);
              await reload();
            }}
            onCancel={() => { }}
            dialogConfig={{ title: config?.title || "Create new " + config?.name.toLocaleLowerCase(), trigger_btn_text: "New" }}
          />
          <ButtonGroup variant="outlined" style={{ marginLeft: 20 }}>
            <Button variant="outlined">
              <ListIcon />
            </Button>
            <Button variant="outlined" disabled>
              <GridViewIcon />
            </Button>
          </ButtonGroup>
        </DRow>

        {config?.rowView ? <DWeb.DRow style={{ flexWrap: "wrap" }}>{result.map((x, idx) => config.rowView?.(x, idx, _actions))}</DWeb.DRow> : null}
        {config?.tableViewColumns ? <DWeb.DTable data={result} columns={config.tableViewColumns} /> : null}
        {!config?.disable_debug ? <DWeb.PrintData data={result}></DWeb.PrintData> : null}
      </DCol>
    );
  };

  export const CommonRoutes = () => {
    return <></>;
  };

  export const DCountDownTimer = React.memo(({ sec, style, onTimeOut }: { sec: number; style?: CSSProperties; onTimeOut?: TVoidCalBack }) => {
    const [seconds, setSeconds] = useState(sec);

    useEffect(() => {
      if (seconds == 0) {
        onTimeOut?.();
        return () => clearTimeout(timer);
      }
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }, [seconds]);

    const minutes = Math.floor(seconds / 60);
    const formattedSeconds = seconds % 60;
    return (
      <DText style={style}>
        {minutes < 10 ? "0" : ""}
        {minutes}:{formattedSeconds < 10 ? "0" : ""}
        {formattedSeconds}
      </DText>
    );
  });

  export function DCircularProgressWithLabel(props: CircularProgressProps & { value: number; text?: string }) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress disableShrink={true} variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {props.text || `${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  export type TPaymentConfig = {
    public_key?: string;
    subscription_name: string;
    title?: string;
    direct_url: string;
    amount: number;
  };

  export const StripePaymentDialog = ({ style, config }: { style?: CSSProperties; config: TPaymentConfig }) => {
    const [open, setOpen] = React.useState(false);

    const PUBLIC_KEY =
      config?.public_key || "pk_test_51LvQTTCtA5cdHPVpCd5yFPSzSSK1NI7gaeqKV7worTKQXPbjUNhVssedYXlJ93sO1T4qMAWZp5BSaSn08lJJb27e00WAdponhq";
    const stripeTestPromise = loadStripe(PUBLIC_KEY);
    const handleClickOpen = () => {
      DWebTS.danalytics.report_action("subscription_btn_clicked");
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <DWeb.DCol style={style}>
        <Button variant="outlined" onClick={handleClickOpen}>
          Get a membership
        </Button>
        <Dialog open={open} keepMounted onClose={handleClose}>
          {config?.title ? <DialogTitle>{config?.title || "Thanks for considering subscription"}</DialogTitle> : null}
          <DialogContent>
            <Elements stripe={stripeTestPromise}>
              <PaymentForm config={config} />
            </Elements>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </DWeb.DCol>
    );
  };

  export function PaymentForm({ config }: { config: TPaymentConfig }) {
    const [output, setOutput] = useState<{ status: "success" | "error"; msg: string } | undefined>(undefined);
    const [result, setResult] = useState<TObject | undefined>(undefined);
    const appCommand = useAppCommand();
    const stripe = useStripe();
    const elements = useElements();
    const [progress, setProgress] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [amount, setAmount] = useState(config?.amount || 5);
    const [couponResult, setCouponResult] = useState("");
    const CARD_OPTIONS = {
      iconStyle: "solid",
      style: {
        base: {},
        invalid: {},
      },
    };

    const applyCoupon = () => {
      switch (coupon) {
        case "":
          setAmount(config.amount);
          setCouponResult("");
          break;
        case "TEST35":
          setAmount(0.35);
          setCouponResult("Coupon applied to have only .35 GBP");
          break;
        case "FAANG80":
          setAmount(config.amount * 0.2);
          setCouponResult("Coupon applied for 80% off");
          break;
        default:
          setCouponResult("invalid coupon");
      }
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      try {
        setProgress(true);
        let card = elements!!.getElement(CardElement);

        if (!stripe || !elements || !card) {
          throw "some error";
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: card,
        });
        if (error) {
          throw error;
        }
        const { id } = paymentMethod;
        const resp = await dnetwork.postSimpleStore(AUTH_ENDPOINT + `/add_subscription_by_stipe`, {
          payment_method_id: id,
          subscription_name: config?.subscription_name || "test",
          auth_token_: appCommand.accountState?.auth_token_,
          override_amount: amount * 100,
        });
        resp.out = DWebTS.decode(resp.out);
        setResult(resp);
        setOutput({ status: "success", msg: "Thanks for teh subscription" });
        appCommand.reloadUser();
      } catch (error: any) {
        DWebTS.danalytics.report_error(new Error(error.message));
        setOutput({ status: "error", msg: error.message });
      }
      setProgress(false);
    };
    if (output && output.status == "success" && result) {
      return (
        <DCol style={{ justifyItems: "center", alignContent: "center", alignItems: "center" }}>
          <CheckCircleIcon style={{ color: dcolors.pallets.green500, fontSize: 100, marginBottom: 14 }} />
          <DText style={{ textAlign: "center", textTransform: "capitalize" }} mode="title">
            {result?.msg || ""}
          </DText>
          <DText style={{ textAlign: "center" }}>{result?.out?.description}</DText>
          <Button variant="contained" style={{ marginTop: 40 }} href={result?.out.recept_url}>
            Download recept
          </Button>
        </DCol>
      );
    }
    return (
      <DCol style={{ marginTop: 30, marginBottom: 30 }}>
        <DText mode="title" style={{ marginBottom: 30, textAlign: "center" }}>
          Payment subscription
        </DText>
        <DTextLines
          style={{ marginBottom: 30 }}
          lines={[
            `Thanks for considering paying £${amount} GBP to help the service running.`,
            "You will be getting acccess to tools for 365 days",
            "If you are not happy with the product, request a return in 7 days",
          ]}
        />

        {output?.status == "error" ? (
          <Alert severity="error" style={{ marginBottom: 20 }}>
            {output.msg}
          </Alert>
        ) : null}
        <form onSubmit={handleSubmit}>
          <DCol style={{ background: dcolors.pallets.yellow100, padding: 20 }}>
            <DText style={{ marginBottom: 18 }}>Your card details:</DText>
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={{ hidePostalCode: true, style: CARD_OPTIONS.style, iconStyle: "solid" }} />
              </div>
            </fieldset>
            <button
              disabled={progress}
              style={{ marginTop: 20, backgroundColor: dcolors.pallets.blue600, border: 0, height: 40, color: "white", cursor: "pointer" }}
            >
              {progress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Pay £" + amount}
            </button>
            <Html
              style={{ marginTop: 20, fontSize: 14 }}
              html={
                config?.direct_url
                  ? `<p><span> Your payment is secured by Stripe payment gateway. We don't store your card details and not used by our system.</span><span>If you want to pay in Stripe website, you can do it <a href="${config?.direct_url}">here</a>, but it will take few hours to enable the subscription.</span>`
                  : "<p><span> Your payment is secured by Stripe payment gateway. We don't store your card details and not used by our system.</span>" +
                  "</p>"
              }
            />
            <DRow style={{ marginTop: 30, justifyItems: "center" }}>
              <DText style={{ flex: 1, fontSize: 13 }}>Do you have a coupon ?</DText>
              <DCol>
                <Input
                  placeholder="Enter coupon"
                  size="small"
                  endAdornment={<Button onClick={applyCoupon}>Apply</Button>}
                  onChange={(e) => setCoupon(e.target.value)}
                  value={coupon}
                />
                {couponResult.length > 0 ? <DText mode="footnote">{couponResult}</DText> : null}
              </DCol>
            </DRow>
          </DCol>
        </form>
      </DCol>
    );
  }
  export const DTextLines = ({ lines, style }: { lines: string[]; style?: CSSProperties }) => {
    return (
      <DCol style={style}>
        {lines.map((x, idx) => (
          <DText style={{ display: "flex", verticalAlign: "middle", textAlign: "left", marginBottom: 10 }} key={idx}>
            <CheckCircleOutlineIcon style={{ color: dcolors.pallets.green500, marginRight: 8 }} />
            {x}
          </DText>
        ))}
      </DCol>
    );
  };

  export class ErrorBoundary extends React.Component {
    state: Readonly<TObject>;
    props: any;
    constructor(props: any) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    componentDidCatch(error: any, errorInfo: any) {
      // Catch errors in any components below and re-render with error message
      console.error(error)
      this.setState({
        error: error,
        errorInfo: errorInfo,
      });
      DWebTS.danalytics.report_fetal(error);
      // You can also log error messages to an error reporting service here
    }

    render() {
      if (this.state.errorInfo as any) {
        // Error path
        return (
          <DWeb.ResponsiveWindow>
            <DCol style={{ marginTop: 50 }}>
              <DBox style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 600, margin: "0 auto", alignItems: "center", overflow: "hidden" }}>
                <img src={unplugged} style={{ width: "100%", objectFit: "contain" }} />
                <DText mode="title" style={{ textAlign: "center", marginTop: 40 }}>
                  Something went wrong
                </DText>
                <DText mode="subtitle" style={{ textAlign: "center", marginTop: 10 }}>
                  {this.state.error.toString()}
                </DText>
                <DRow>
                  <Chip
                    style={{ background: dcolors.pallets.red400, color: "white", width: 150, marginTop: 30, marginRight: 20 }}
                    label={<DLink href="/">Back to Home</DLink>}
                  ></Chip>
                  <Chip
                    style={{ background: dcolors.pallets.green400, color: "white", width: 150, marginTop: 30 }}
                    label={<DLink href={"https://www.facebook.com/profile.php?id=100087654423509"}>Report this issue</DLink>}
                  ></Chip>
                </DRow>
              </DBox>
            </DCol>
          </DWeb.ResponsiveWindow>
        );
      }
      // Normally, just render children
      return this.props.children;
    }
  }

  export const DLink = ({ href, children }: { href: string; children: any }) => {
    return (
      <a href={href} style={{ textDecoration: "none", color: "white" }}>
        {children}
      </a>
    );
  };

  export const DFAQ = ({ data, header }: { data: { title: string; qa: { q: string; a: string }[] }[]; header: string }) => {
    return (
      <DCol>
        <DBox>
          <DText mode="title" style={{ marginBottom: 20 }}>
            {header}
          </DText>
          {data.map((x, idx) => {
            return (
              <DCol key={idx}>
                <DText mode="subtitle" style={{ marginBottom: 20, marginTop: 20, fontSize: 20, color: dcolors.pallets.red400 }}>
                  {x.title}
                </DText>
                <DCol style={{ marginLeft: 20 }}>
                  {x.qa.map((y, idy) => (
                    <DText key={idy} style={{ lineHeight: 2 }}>
                      <a href={`#section_${idx}.${idy}`}>{y.q}</a>
                    </DText>
                  ))}
                </DCol>
              </DCol>
            );
          })}
        </DBox>
        {data.map((x, idx) => {
          return (
            <DBox key={idx} style={{ marginTop: 30 }}>
              <DText mode="subtitle" style={{ marginBottom: 20, color: dcolors.pallets.red400 }}>
                {x.title}
              </DText>
              <DCol style={{ marginLeft: 20 }}>
                {x.qa.map((y, idy) => (
                  <DCol id={`section_${idx}.${idy}`} style={{ paddingTop: 30, paddingBottom: 30, borderBottom: "1px solid #f1f1f1" }}>
                    <DText key={idy} style={{ lineHeight: 1.2, fontSize: 24, color: dcolors.pallets.grey800, marginBottom: 12 }}>
                      {y.q}
                    </DText>
                    <DWeb.Html html={y.a} style={{ marginTop: 10 }} />
                  </DCol>
                ))}
              </DCol>
            </DBox>
          );
        })}
      </DCol>
    );
  };
  // use https://metatags.io/ to test your website
  export type TSeoConfig = { page_title?: string; page_description?: string; keywords?: string; img?: string };
  export const DHelmet = ({ config }: { config: TSeoConfig }) => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{config.page_title || "Welcome to Home page"}</title>
        <meta name="description" content={config.page_description || " Welcome to this page"} />
        <meta name="keywords" content={config.keywords || ""} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="D Software pvt ltd"></meta>
        <meta property="og:title" content={config.page_title}></meta>
        <meta property="og:image" content={config.img || "https://www.example.com/images/uk-test-online-course.jpg"}></meta>
      </Helmet>
    );
  };

  export const GoogleLoginButton = ({ google_client_id }: { google_client_id: string }) => {
    const [error, setError] = useState("");
    const appCommand = useAppCommand();

    DWebTS.useEffectAsync(async () => {
      const callback = async (response: any) => {
        try {
          const data: TObject = jwt_decode(response.credential);
          dlog.obj(data);
          let res = await dnetwork.postSimpleStore(AUTH_ENDPOINT + "/google_login", data);
          appCommand.setUserLogin(DWebTS.decode(res.out));
        } catch (e: any) {
          setError("Login Failed. " + e.message);
        }
      };
      try {
        await DWebTS.loadScript("https://accounts.google.com/gsi/client");
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: google_client_id,
          callback: callback,
        });
        // @ts-ignore
        google.accounts.id.renderButton(document.getElementById("google_login_btn") as HTMLElement, { theme: "outline", size: "medium" });
        //google.accounts.id.prompt();
      } catch (e) {
        throw e;
      }
    }, []);
    return (
      <DCol style={{ justifyContent: "center", alignItems: "center" }}>
        {error.length > 0 ? (
          <Alert style={{ marginBottom: 10 }} color="error">
            {error}
          </Alert>
        ) : null}
        <div id="google_login_btn" />
      </DCol>
    );
  };

  // export Avatar with Exit Pictire Button
  export const DAvatarWithChangePicture = ({
    src,
    alt,
    sx,
    onChange,
  }: {
    src?: string;
    onChange?: (files: File) => any;
    sx?: any;
    alt?: string | undefined;
  }) => {
    return (
      <div style={{ position: "relative" }}>
        <Avatar src={src} alt={alt} sx={sx} />
        {onChange ? (
          <DUploadButton
            style={{ position: "absolute", bottom: -10, right: -10, color: dcolors.pallets.black }}
            onChange={(obj: any) => {
              onChange(obj[0] as File);
            }}
          />
        ) : null}
      </div>
    );
  };

  export const DUploadButton = ({ onChange, style }: { onChange: TAnyCallback; style?: CSSProperties }) => {
    return (
      <IconButton color="primary" aria-label="upload picture" component="label" style={style}>
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e: any) => {
            onChange(e.target.files);
          }}
        />
        <PhotoCamera />
      </IconButton>
    );
  };


  export const DSplitButton = ({ actions }: { actions: { text: string, onClick: TVoidCalBack }[] }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      event.preventDefault();
      event.stopPropagation();
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <ButtonGroup variant="contained" style={{}}>
        <Button onClick={actions[0].onClick}>
          {actions[0].text}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-label="select option"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          ▼
        </Button>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {actions.slice(1).map((option) => (
            <MenuItem
              key={option.text}
              onClick={(e) => {
                option.onClick();
                handleClose();
                e.stopPropagation();
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </ButtonGroup>
    );
  }

  export const DImage = ({ src, style, href }: { href?: string, src?: string; style?: CSSProperties }) => {
    if (href) {
      return <a href={href}>
        <img src={src || image_placeholder} style={style} />
      </a>
    }
    return <img src={src || image_placeholder} style={style} />;
  };

  // dont use key as it's a define datat type
  export const KeyValuePair = ({ key1, value }: { key1: string; value: string }) => {
    return (
      <div style={{ marginBottom: 8 }}>
        <DWeb.DText style={{ color: dcolors.pallets.grey500, fontSize: "0.9rem" }}>{key1}</DWeb.DText>
        <DWeb.DText style={{ overflow: "hidden", maxHeight: "1.5rem" /*only 1 line*/ }}>{value}</DWeb.DText>
      </div>
    );
  };

  export const ErrorPage = ({ title, subtitle, extra }: { title: string; subtitle: string; extra: any }) => {
    return (
      <ResponsiveWindow>
        <div style={{ margin: "0 auto", textAlign: "center", width: 400, marginTop: 40 }}>
          <ErrorIcon style={{ color: dcolors.pallets.red700, fontSize: 140, marginTop: 40, marginBottom: 0 }} />
          <DText mode="title" style={{ fontSize: 40, marginTop: 10 }}>
            {title}
          </DText>
          <DText mode="subtitle" style={{ marginTop: 20 }}>
            {subtitle}
          </DText>
          {extra}
        </div>
      </ResponsiveWindow>
    );
  };


  export const EmptyView = ({ title, subtitle, icon, extra }: { title?: string; subtitle?: string; icon?: string, extra?: any }) => {
    return (
      <DWeb.DCol style={{ margin: "0 auto", textAlign: "center", width: 400, marginTop: 40, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <ErrorIcon style={{ color: dcolors.pallets.red700, fontSize: 140, marginTop: 40, marginBottom: 0 }} />
        <DText style={{ fontSize: 24, marginTop: 10, textTransform: 'uppercase', letterSpacing: 1.5, fontWeight: 'bold' }}>
          {title}
        </DText>
        <DText style={{ marginTop: 5, fontSize: 20, opacity: 0.5 }}>
          {subtitle}
        </DText>
        {extra}
      </DWeb.DCol>
    );
  };

  export const DropDownMenu = ({ style, actions, icon, groupButton }: { style?: CSSProperties, groupButton?: boolean, icon?: JSX.Element; actions: { text: string; onClick: TVoidCalBack }[] }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      event.preventDefault();
      event.stopPropagation();
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        {groupButton ? (<Button
          style={{ height: 36, borderRadius: 0 }}
          color="primary"
          size="small"
          variant="contained"
          aria-label="select option"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          ▼
        </Button>) : (<IconButton

          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {icon || <MoreVertIcon />}
        </IconButton>)}

        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {actions.map((option) => (
            <MenuItem
              key={option.text}
              onClick={(e) => {
                option.onClick();
                handleClose();
                e.stopPropagation();
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  // chart
  const colorPalette = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FFC107"];

  function generateColors(num: number) {
    const colors = [];
    for (let i = 0; i < num; i++) {
      colors.push(colorPalette[i % colorPalette.length]);
    }
    return colors;
  }

  export function PieChart() {
    let options: ApexCharts.ApexOptions = {
      grid: {
        show: false,
      },

      chart: {
        id: "basic-bar",
        zoom: {
          enabled: false,
        },
        height: 100,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      },
    };
    let series = [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ];

    return <Chart type="area" series={series} options={options} />;
  }

  export const DTabView = ({ data, style, config, extraRightView }: {
    config: { tabview: 'tab' | 'chip' },
    data: { text: string; view?: JSX.Element }[]; style?: CSSProperties
    extraRightView?: JSX.Element
  }) => {
    const [value, setValue] = React.useState("0");
    data = data || []
    return (
      <TabContext value={value}>
        <DCol style={style}>
          {config?.tabview == 'tab' ? <TabList
            value={value}
            onChange={(i: any, v: string) => setValue(v)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {data.map((x, idx) => (
              <Tab label={x.text} value={idx + ""} />
            ))}
          </TabList> : null}
          {config?.tabview == 'chip' ? <DWeb.DRow style={{ flex: 0 }}>
            {data.map((x, idx) => (
              <Chip label={x.text} onClick={() => { setValue(idx + '') }} style={{ margin: 4, marginRight: 10 }} color={idx + '' == value ? 'success' : 'default'} />
            ))}
            {extraRightView}
          </DWeb.DRow> : null}
          {data.map((x, idx) => (
            <TabPanel value={idx + ""} style={{ margin: 0, padding: 0, display: 'flex' }}>
              {x.view}
            </TabPanel>
          ))}
        </DCol>
      </TabContext>
    );
  };

  // Full Screen Dialog
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  export function DFullScreenDialog({ open, onClose, content, style }: { open: boolean; onClose: TVoidCalBack; content: JSX.Element, style?: CSSProperties }) {
    return (
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} style={{ marginLeft: 100, ...style }}>
        <DWeb.DCol style={{ position: 'relative' }}>
          <DWeb.DRow style={{ flex: 0, background: 'black', padding: 10 }}>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" style={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </DWeb.DRow>
          {content}
        </DWeb.DCol>



      </Dialog>
    );
  }

  export const DTextTwo = ({ first, second, style }: { first: string, second: string, style?: CSSProperties }) => {

    const style1: CSSProperties = { textAlign: 'center' }
    const style2: CSSProperties = { textAlign: 'center', fontWeight: 'bold', fontSize: 23 }
    return (<DCol style={{ ...style }}>
      <DText style={style1}>{first}</DText>
      <DText style={style2}>{second}</DText>
    </DCol>)
  }


  export const FirebasePdfViewer = React.memo(({ url }: { url: string }) => {
    const [pdfFile, setPdfFile] = useState<string | null>(null);
    DWebTS.useEffectAsync(async () => {
      if (url == '') {
        return
      }
      const storageRef = ref(DWebTS.globalData.firebase_storage!!, url);
      const path = await getDownloadURL(storageRef)
      setPdfFile(path)
    }, [url]);
    if (!pdfFile) {
      return <DWeb.DText>Not found</DWeb.DText>
    }
    return (
      <iframe src={pdfFile || ''} style={{ display: 'flex', flex: 1, border: 0 }} />
    );
  })

  export const InputWithButton = ({ style, label, onSubmit, size }: { onSubmit: TStringCallback, label: string, style?: CSSProperties, size?: any }) => {
    const [loading, setloading] = React.useState(false)
    const [val, setval] = React.useState('')

    const onSubmit1 = async () => {
      try {
        setloading(true)
        await onSubmit(val)
        setval('')
        setloading(false)
      } catch (e) { setloading(false) }
    }
    return <TextField
      style={{ ...style }}
      label={label}
      value={val}
      size={size}
      onChange={e => setval(e.target.value)}
      onKeyDown={e => {
        if (e.keyCode == 13) {
          onSubmit1()
        }
      }}
      InputProps={{
        endAdornment: loading ? <CircularProgress size={20} /> : <InputAdornment position="start" style={{ width: '100%', flex: 0 }}><Button style={{ flex: 0 }} onClick={() => {
          onSubmit1()
        }}>Submit</Button></InputAdornment>,
      }}
    />
  }

  export const DPageHeader = ({ title, rightView, style }: { title: string, rightView?: JSX.Element, style?: CSSProperties }) => {
    return (<DWeb.DRow style={{ marginTop: 30, marginBottom: 20, flex: 0, ...style }}>
      <DWeb.DText style={{ fontSize: 25, fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: 1, color: dcolors.pallets.grey800 }}>{title}</DWeb.DText>
      <DWeb.DSpace />
      {rightView}
    </DWeb.DRow>)
  }


  type CSSProperties1 = {
    [key: string]: React.CSSProperties;
  };

  export class DStyleSheet {
    static create<Styles extends CSSProperties1>(styles: Styles): Styles {
      return styles;
    };
  };

  // this used in NotesViews in grdodk-office
  export const LazyTabView = ({ initialTab, routes, tabStyle, style }: { initialTab: number, style?: CSSProperties, tabStyle?: CSSProperties, routes: { label: string, lazyView: any, rightTabView?: any }[] }) => {
    const [Component, setComponent] = useState<any>(null);
    const [RightTabView, setRightTabView] = useState<any>(null);
    const [selectCount, setSelectCount] = useState(initialTab || 0)
    React.useEffect(() => {
      onClick(initialTab || 0)
    }, [initialTab])
    const onClick = async (i: number) => {
      const module = await routes[i].lazyView();
      setComponent(() => module.default);
      setRightTabView(routes[i].rightTabView)
      setSelectCount(i)
    }
    return (
      <DWeb.DCol style={style}>
        <DWeb.DRow style={{ marginBottom: 20, flex: 0 }}>
          {routes.map((route, i) => (
            <Button key={i} onClick={() => onClick(i)} style={{ borderBottom: selectCount == i ? '4px solid grey' : '4px solid transparent', borderRadius: 0, paddingLeft: 20, paddingRight: 20, margin: 2, ...tabStyle, }}>
              {route.label}
            </Button>
          ))}
          <DSpace />
          {RightTabView}
        </DWeb.DRow>

        {Component && <Component />}
      </DWeb.DCol>
    );
  };

  // This module helps to create a color bar as per the teh distribution
  export const ColorBar = ({ config }: { config: TObject }) => {
    let total = Object.values(config).reduce((acc, curr) => acc + curr, 0);
    return <DWeb.DRow style={{ flex: 0 }}>
      {Object.keys(config).map(x => <div style={{ height: 5, width: `${config[x] / total * 100}%`, background: x }} key={x} />)}
    </DWeb.DRow>
  }

  // A great illustartion with image, title and subtitle text
  export const IllustrationHelper = ({ title, subtitle, image, style, points }: { title: string, subtitle?: string, image: any, style?: CSSProperties, points?: string[] }) => {
    return <DWeb.DRow style={{ padding: 40, flex: 0, alignItems: 'center', justifyContent: 'center', ...style }}>
      <img src={image} style={{ width: '40%', flex: 0 }} />
      <DWeb.DCol style={{ flex: 2, justifyContent: 'center', margin: 80 }}>
        <DWeb.DText style={{ marginBottom: 40, fontWeight: 'bold', fontSize: 24, color: dcolors.pallets.grey800, textAlign: 'left' }}>{title}</DWeb.DText>
        {subtitle ? <DWeb.Html style={{ fontSize: 15, color: dcolors.pallets.grey500, lineHeight: 1.5, textAlign: 'left' }} html={subtitle} /> : null}
        {points?.map(x => <DWeb.Html style={{ fontSize: 20, color: dcolors.pallets.green600, textAlign: 'left' }} html={'✓ &nbsp;  ' + x} />)}
      </DWeb.DCol>
    </DWeb.DRow >
  }

  // privacy view to blue out 
  export const PrivacyView = ({ children, lock }: { children: any, lock?: boolean }) => {
    let [lock1, setLock] = React.useState<boolean>(lock || true)
    return <div style={{ position: 'relative' }}>{children}
      {lock1 ? <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, opacity: 0.9, background: 'white' }}></div> : null}
      <FormControlLabel
        style={{ position: 'absolute', top: 5, right: 5 }}
        control={<Switch checked={lock1} onChange={(e) => setLock(e.target.checked)} />}
        label="Lock"
      />
      {lock1 ? <DText style={{ position: 'absolute', top: '50%', left: 0, right: 0, textAlign: 'center' }}>Content is locked. Please unlock to view this.ß</DText> : null}
    </div>
  }

  // chip actions list
  export const DChipActionList = ({ actions, style }: { actions: { icon?: any, text?: string, onClick: TVoidCalBack }[], style?: CSSProperties }) => {
    return <div style={style}>
      {actions.map((x, idx) => {
        let style: any = {}
        if (idx != 0) {
          style.borderTopLeftRadius = 0
          style.borderBottomLeftRadius = 0
          style.borderLeft = '1px solid white'
        }
        if (idx != actions.length - 1) {
          style.borderTopRightRadius = 0
          style.borderBottomRightRadius = 0
        }
        return <Chip key={idx} icon={x.icon} label={x.text} onClick={x.onClick} style={style}></Chip>
      })}
    </div>
  }

  // Cusom Promt as a text area.
  export const DPromptModel = ({ onSubmit, config }: { onSubmit: TStringCallback, config?: { btn_text?: string, title?: string, subtitle?: string, initValue?: string, btn_style?: CSSProperties } }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userInput, setUserInput] = useState(config?.initValue || '');
    React.useEffect(() => {
      setUserInput(config?.initValue || '')
    }, [config])

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleInputChange = (event: any) => {
      setUserInput(event.target.value);
    };

    return (
      <div>
        <Button variant="outlined" onClick={handleOpen} style={config?.btn_style}>
          {config?.btn_text || 'Open prompt'}
        </Button>

        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              display: 'flex',
              flexDirection: 'column',
              transform: 'translate(-50%, -50%)',
              width: 450,
              height: 450,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 2,
            }}
          >
            <DText style={{ marginBottom: 20, fontWeight: 'bold' }}>{config?.title || 'Enter the input'}</DText>
            {config?.subtitle ? <DText style={{ marginBottom: 20 }}>{config?.subtitle || 'Enter the input'}</DText> : null}
            <textarea
              style={{ flex: 1, padding: 10 }}
              value={userInput}
              onChange={handleInputChange}
            />
            <DWeb.DRow style={{ marginTop: 20, flex: 0 }}>
              <DSpace />
              <LoadingButton loading={loading} variant="contained" onClick={async () => {
                setLoading(true)
                try {
                  await onSubmit(userInput);
                } catch (e) {
                }
                setLoading(false)
                handleClose()
              }} style={{ marginRight: 20, }}>
                Submit
              </LoadingButton>
              <Button onClick={handleClose} style={{}}>
                Cancel
              </Button>
            </DWeb.DRow>

          </Box>
        </Modal>
      </div>
    );
  }


  export type TCaseManagerHook = {
    data: any[];
    loading: boolean;
    api: {
      create: (data: any) => Promise<void>;
      update: (_id: string, data: any) => Promise<void>;
      delete: (_id: string) => Promise<void>;
      getAll: (_id: string) => Promise<void>;
    }
  }

  export const DCaseManager = ({ title, caseConfig, hook, style, actions }: { title: string, caseConfig: { model: TFormDataModel }, hook: TCaseManagerHook, style?: CSSProperties, actions?: { onOpen?: TObjCalBack } }) => {
    return <DBox style={{ padding: 0, ...style }}>
      <DRow style={{ borderBottom: '1px solid grey', flex: 0, padding: 20 }}>
        <DText style={{ flex: 1 }} mode='title'>{title}</DText>
        <DWeb.DFormDialog model={caseConfig.model} onSubmit={async (data) => {
          await hook.api.create(data);
        }} dialogConfig={{ title: 'All Crack Detector', trigger_btn_text: 'Create new Case' }} />
      </DRow>

      {hook.data.length == 0 ? <DWeb.EmptyView title="No case found" subtitle="Please create your first case" /> : (
        <DWeb.DTable data={hook.data} columns={[
          { field: '_id', format: x => x.substring(0, 5).toUpperCase() },
          { field: 'name', style: { cursor: 'pointer', color: 'blue' }, onClick: (y) => { actions?.onOpen?.(y) } },
          { field: 'client_name' },
          { field: 'client_phone' },
        ]}
          actions={[
            { text: 'see progress', onClick: (y) => actions?.onOpen?.(y) },
            { text: 'delete', onClick: (obj) => { hook.api.delete(obj._id) } },
            { text: 'edit', onClick: () => { } },
          ]}

          style={{ boxShadow: 'none' }}
        />
      )}
      {hook.loading ? <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, background: '#ffffff88', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress size={20} /> </div> : null}
    </DBox>
  }

  export const DToDoView = () => {
    return <DText style={{ padding: 20, textAlign: 'center' }}>TODO - This is a place holder will be TODO</DText>
  }

  // this will implemnet steps UI with component
  export function DStepper({ config, steps, style, onFinish }: { config?: { initStep?: number }, steps: { label: string, component: any }[], style?: CSSProperties, onFinish?: TVoidCalBack }) {
    const [activeStep, setActiveStep] = useState(config?.initStep || 0);

    const handleNext = () => {
      if (activeStep == steps.length - 1) {
        onFinish?.()
        return
      }
      setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevStep) => prevStep - 1);
    };

    return (
      <DBox style={style}>
        <Stepper activeStep={activeStep} alternativeLabel style={{ padding: 20 }}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DCol style={{ borderTop: '1px solid #f1f1f1', borderBottom: '1px solid #f1f1f1', marginBottom: 20 }}>
          {steps[activeStep].component}
        </DCol>
        <DRow style={{ flex: 0 }}>
          <DSpace />

          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </DRow>
      </DBox>
    );
  }

  export type TFileInfo = {
    name: string; url: string; type: string; size: number,
  }
  export const DFileUploader = ({ onUploadComplete, config, style }: { style?: CSSProperties, onUploadComplete: (arg: TFileInfo[]) => any, config: DWebTS.TFileConfig }) => {
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const appCommand = useAppCommand()

    const handleDrop = (event: any) => {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      setUploadedFiles([...uploadedFiles, ...files]);
    };
    const handleFileInputChange = (event: any) => {
      const files = Array.from(event.target.files);
      setUploadedFiles([...uploadedFiles, ...files]);
    };


    const handleDragOver = (event: any) => {
      event.preventDefault();
    };

    return (
      <DCol style={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '2px dashed #ccc',
            borderRadius: '4px',
            minHeight: 300,
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f9f9f9'
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <DSpace />
          <div style={{ padding: 20, flex: 1, justifySelf: 'center', textAlign: 'center', alignItems: 'center' }}>
            <FileUploadOutlined style={{ fontSize: 40 }} />
            <DText style={{ marginTop: 20 }}>Drag and drop files here</DText>
            <DText style={{}}>Or</DText>
            <Button variant="outlined" component="label" size="small">
              Select Files
              <input type="file" style={{ display: 'none' }} onChange={handleFileInputChange} multiple />
            </Button>
          </div>

          <DTable data={uploadedFiles} columns={[{ field: 'name' }, { field: 'size', format: (x) => CommonTS.formatFileSize(Number(x)) }, { field: 'type' }, { field: 'lastModifiedDate' }]}
            style={{ background: '#f9f9f9', boxShadow: 'none', margin: 5, display: uploadedFiles.length == 0 ? 'none' : 'flex' }} />
          <DRow style={{ flex: 1, padding: 10, width: '100%', display: uploadedFiles.length == 0 ? 'none' : 'flex' }}>
            {appCommand.isDebugMode() ? <DText>{`using storage config from:${appCommand.getAppConfig()?.firebase_config?.projectId}`}</DText> : null}
            <DSpace />
            <LoadingButton loading={loading} variant="contained" onClick={async () => {
              setError(null)
              setLoading(true)
              try {
                let data = await DWebTS.uploadFiles(uploadedFiles, config)
                let result: TFileInfo[] = []
                uploadedFiles.forEach((x, idx) => {
                  result.push({ name: x.name, url: data[idx], size: x.size, type: x.type })
                })
                await onUploadComplete(result)
                setUploadedFiles([])
              } catch (e: any) {
                setError('Upload Failed' + e.message)
              }
              setLoading(false)
            }}>Upload</LoadingButton>
            <Button style={{ marginLeft: 10 }} onClick={(() => setUploadedFiles([]))}>Clear All</Button>
          </DRow>
          {error ? <Alert severity="error" style={{ marginTop: 10, width: '100%' }}>
            {error}
          </Alert> : null}
        </Box>
      </DCol>

    )
  }

  export const DBreadcrumb = ({ items }: { items: { label: string, href?: string }[] }) => {
    return (
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {items.map((item, index) => (
          <Link key={index} color="inherit" href={item.href}>
            {item.label}
          </Link>
        ))}
      </Breadcrumbs>
    );
  };

  export const DMarquee = ({ text, style }: { text: string, style?: CSSProperties }) => {
    const marqueeStyles = {
      width: '100%',
      overflow: 'hidden',
    };

    const contentStyles = {
      display: 'inline-block',
      //whiteSpace: 'nowrap',
      animation: 'marquee-animation 10s linear infinite',
    };

    return (
      <div style={{ ...marqueeStyles, ...style }}>
        <DWeb.Html style={contentStyles} html={text} />
      </div>
    );
  }

  // this will display a stock high low var
  export const StockBar = ({ data }: { data: yahooFin.TStockInfo }) => {
    let left = (data.ltp - data.low) / (data.high - data.low) * 100;
    let pleft = ((data.previousClose || 0) - data.low) / (data.high - data.low) * 100;
    if (pleft < 0) { pleft = -4 }
    if (pleft > 100) { pleft = 104 }
    let vol = (data.high - data.low) / data.low * 100
    return <DWeb.DCol style={{ maxWidth: 200 }}>
      <DWeb.DRow style={{ marginBottom: 5 }}>
        <span>PC:{data.previousClose?.toFixed(2)}</span>
        <DWeb.DSpace></DWeb.DSpace>
        <span>LTP:{data.ltp.toFixed(2)}</span>
      </DWeb.DRow>
      <DWeb.DRow style={{ position: 'relative' }}>
        <div style={{ flex: 1, marginRight: 5, background: dcolors.pallets.grey300, height: 5 }}></div>
        <div style={{ flex: 1, marginRight: 5, background: dcolors.pallets.grey300, height: 5 }}></div>
        <div style={{ flex: 1, marginRight: 5, background: dcolors.pallets.grey300, height: 5 }}></div>
        <div style={{ flex: 1, background: dcolors.pallets.grey300, height: 5 }}></div>
        <div style={{ background: 'red', position: 'absolute', left: left + '%', top: 0, width: 6, height: 6 }} />
        <div style={{ background: 'blue', position: 'absolute', left: pleft + '%', top: 0, width: 6, height: 6 }} />
      </DWeb.DRow>
      <DWeb.DRow style={{ marginTop: 5 }}>
        <span>L:{data.low.toFixed(2)}</span>
        <DWeb.DSpace />
        <span>{vol.toFixed(2)}%</span>
        <DWeb.DSpace />
        <span>H:{data.high.toFixed(2)}</span>
      </DWeb.DRow>
    </DWeb.DCol>
  }

  export const StockLTP = ({ data }: { data: yahooFin.TStockInfo }) => {
    let left = (data.ltp - data.low) / (data.high - data.low) * 100;
    let pleft = ((data.previousClose || 0) - data.low) / (data.high - data.low) * 100;
    if (pleft < 0) { pleft = -4 }
    if (pleft > 100) { pleft = 104 }
    let vol = (data.high - data.low) / data.low * 100
    return <DWeb.DCol style={{ color: data.pchange >= 0 ? 'green' : 'red' }}>
      <DText style={{ fontSize: 18, fontWeight: 'bolder', color: data.pchange >= 0 ? 'green' : 'red' }}>{data.pchange.toFixed(2)}%</DText>
      <DText style={{ marginTop: 0, color: data.pchange >= 0 ? 'green' : 'red' }}>{data.ltp.toFixed(2)} ( {data.pchange >= 0 ? '+' : ''}{data.change.toFixed(2)} )</DText>
    </DWeb.DCol>
  }

  export const DChip = ({ text, loading, onClick, style, href }: { href?: string, text: string, onClick?: TVoidCalBack, loading?: boolean, style?: CSSProperties }) => {
    return <LoadingButton size="small" loading={loading} onClick={onClick} style={{
      borderRadius: 10,
      textTransform: 'none',
      background: dcolors.pallets.grey100, color: 'black', boxShadow: 'none', ...style
    }} variant="contained"
      href={href || ''}
      target="_blank"
      loadingPosition="start"
    >{text}</LoadingButton>
  }

  export const DashboardNumber = ({ number, text, style }: { number: string, text: string, style?: CSSProperties }) => {
    return <DCol style={{ padding: 10, ...style }}>
      <DText style={{ textAlign: 'center', fontSize: 40, fontWeight: 'bolder', color: 'white', lineHeight: 1 }}>{number}</DText>
      <DText style={{ textAlign: 'center', fontSize: 13, textTransform: 'uppercase', color: 'white', opacity: 0.6, lineHeight: 1, marginTop: 10 }}>{text}</DText>
    </DCol>
  }

  export const CodeEditior = ({ height, code, style, onChange }: { height: any, code: string, style?: CSSProperties, onChange: TStringCallback }) => {
    let [code1, setCode] = React.useState<string>(code || "");
    React.useEffect(() => {
      setCode(code)
    }, [code])
    const editor = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    return (<div style={{ paddingTop: 20, ...style }}><Editor
      onMount={(e) => {
        editor.current = e;
      }}
      width={'100%'}
      height={height}
      defaultLanguage="json"
      options={{
        renderLineHighlight: "none",
        formatOnPaste: true,
        formatOnType: true,
        autoIndent: "brackets",
        minimap: {
          enabled: false,
        },
      }}
      defaultValue={code1}
      value={code1}
      onChange={(data) => {
        if (data) {
          setCode(data);
          onChange(data.toString())
        }
      }}
    /></div>)
  }

  export const AbsoluteDiv = () => {
    return <div style={{
      "position": "absolute",
      "top": 0,
      "left": 0,
      "right": 0,
      "border": 0,
      "opacity": 0.9,
      "width": "100%",
      "height": "100%"
    }} />
  }

  export function SimpleStoreVisibility({ id, db_name, defaultVal, onUpdateSuccess }: { db_name: string, id: string, defaultVal: string, onUpdateSuccess?: TVoidCalBack }) {
    let appCommand = useAppCommand()
    return <DWeb.DPromptModel onSubmit={async (input: string) => {
      // http://localhost/api/secure_test/visibility/add?_id={{id1}}&auth_token_=debug&username=user1
      await appCommand.postSimpleStore(`https://simplestore.dipankar.co.in/api/${db_name}/visibility/replace`, { _id: id, username: input })
      await onUpdateSuccess?.()
    }} config={{
      btn_text: 'Share',
      initValue: defaultVal,
      title: 'Share this entry with other uuser',
      subtitle: 'To share this entry with other users, please add their usernames separated by commas. Once you share, they will need to refresh the page to view this entry. They will also be able to edit it once it has been shared.'
    }}></DWeb.DPromptModel>
  }

  export function DHelpText({ title, subtitle, style, list }: { title: string, subtitle: string, style?: CSSProperties, list?: string[] }) {
    return <DWeb.DCol style={style}>
      <DWeb.DText mode='title' style={{ marginBottom: 20 }}>{title}</DWeb.DText>
      <DWeb.DText style={{ marginBottom: 20 }}>{subtitle}</DWeb.DText>
      {list ? (
        list.map(x => <DWeb.DText style={{ padding: 5, color: dcolors.pallets.black }}>✅︎ {x}</DWeb.DText>)
      ) : null}
    </DWeb.DCol>
  }

  export const DSelectInput = ({ value, onChange, options }: { value: string, onChange: TStringCallback, options: { value: string, text: string }[] }) => {
    return <select value={value} onChange={x => onChange(x.target.value)} style={{ height: 36 }}>
      {options.map(x => <option value={x.value}>{x.text}</option>)}
    </select>
  }

  export function DPopUpMenu({ btnText, actions, btnStyle }: { btnText: string, btnStyle?: CSSProperties, actions: { text: string, onClick?: TVoidCalBack, href?: string }[] }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={btnStyle}
        >
          {btnText}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >

          {actions.map((x, idx) => {
            let onclick = undefined
            if (x.onClick) {
              onclick = () => {
                handleClose()
                x.onClick?.()
              }
            }
            return (<MenuItem key={idx} >
              <Button href={x.href} onClick={onclick}>{x.text}</Button>
            </MenuItem>)
          })
          }
        </Menu>
      </div>
    );
  }

  // JSON as Table: This will show any JSON data ina tabular format
  export const JSONAsTable = ({ data }: { data: TObject }) => {
    const renderValue = (value: any) => {
      if (Array.isArray(value)) {
        return (
          <ul>
            {value.map((item, index) => (
              <li key={index}>{renderValue(item)}</li>
            ))}
          </ul>
        );
      } else if (typeof value === 'object' && value !== null) {
        return <JSONAsTable data={value} />;
      } else {
        return <span>{String(value)}</span>;
      }
    };

    if (!data) {
      return <div>No data available.</div>;
    }
    return (
      <div>
        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #ddd' }}>
          <tbody>
            {Object.entries(data).map(([key, value], index) => (
              <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{renderValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  export function DashboardTiles({ data }: { data: { link?: string, title: string, value: string, subtext?: string, color?: string, errorColor?: boolean }[] }) {
    return (<DWeb.DRow>
      {data.map((x, idx) => {
        let backColor = x.color || drandom.getRandomColor();
        if (x.errorColor != undefined) {
          backColor = x.errorColor ? dcolors.pallets.red500 : dcolors.pallets.green500;
        }
        return (<div key={idx} style={{ flex: '0 0 auto', width: 250, height: 150, padding: 30, margin: 10, color: 'white', background: backColor, alignItems: 'center' }}>
          <DWeb.DText style={{ textAlign: 'center', color: dcolors.pallets.grey300, marginBottom: 5, fontSize: 16 }}>{x.title}</DWeb.DText>
          <DWeb.DText style={{ textAlign: 'center', color: 'white', marginBottom: 5, fontSize: 36, fontWeight: 'bold' }}>{x.value}</DWeb.DText>
          {x.subtext ? <DWeb.DText style={{ textAlign: 'center', color: dcolors.pallets.grey300, marginBottom: 5, fontSize: 12 }}>{x.subtext}</DWeb.DText> : null}
        </div>)
      })
      }
    </DWeb.DRow>)
  }

  // Mostly used by single app just add this header to the top.
  export function PageHeaderMenu({ style, logo, rightDropDownActions }: { style?: CSSProperties, logo: any, rightDropDownActions?: { text: string, href?: string, onClick?: TVoidCalBack }[] }) {
    let appCommand = DWeb.useAppCommand()
    return <DWeb.DRow style={{ alignItems: 'center', zIndex: 1, color: 'white', background: appCommand.getAppConfig()?.primary_color, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, position: 'fixed', top: 0, right: 0, left: 0, ...style }}>
      <a href={"/"}>
        <DWeb.DImage src={logo} style={{ maxHeight: 20 }} />
      </a>
      <DWeb.DSpace />
      {rightDropDownActions ? (appCommand.accountState?._id ? (
        <DWeb.DPopUpMenu btnStyle={{ color: 'white' }} btnText={`Welcome ${appCommand.accountState.name}`} actions={[...rightDropDownActions,
        { text: 'Account Settings', href: '/account' },
        { text: 'Log Out', onClick: () => { appCommand.logout() } }
        ]}></DWeb.DPopUpMenu>
      ) : (
        <DWeb.DRow style={{ flex: 1, justifyContent: 'flex-end' }}>
          <Button style={{ color: 'white' }} size="small" href="/account" variant="contained">Sign Up</Button>
        </DWeb.DRow>
      )) : null}
    </DWeb.DRow>
  }

  // Splt data veiw is a view of simplestore data with table with expaned view way
  export function SplitDataView({ url, columns, getDetailsText, buildSearchParams }: {
    url: string,
    columns: DTableColumn[];
    getDetailsText: (obj: TObject) => string,
    buildSearchParams: (x: string) => TObject
  }) {
    const networkConn = DHook.useNetwork(url, true)
    const [selected, setSelected] = useState<TObject | undefined>()
    return (<div>
      <DWeb.DCol style={{ height: '50vh', padding: 10, background: '#f1f1f1' }}>
        <DWeb.DRow style={{ flex: 0, paddingTop: 10, paddingBottom: 20, alignItems: 'center' }}>
          <DText style={{ flex: 1 }}>Data points</DText>
          <SearchButton onSearch={x => networkConn.api.setParams(buildSearchParams(x))} />
        </DWeb.DRow>
        {networkConn.state.loading ? <Alert severity="info" >Loading...</Alert> : null}
        {networkConn.state.data ? <DWeb.DTable
          data={networkConn.state.data as TObject[]}
          columns={columns}
          actions={[
            {
              text: 'delete',
              onClick: async (obj) => {
                await dnetwork.postSimpleStore(`${url}/delete`, { _id: obj._id })
                networkConn.api.reload()
              }
            }
          ]}
          onRowClicked={x => setSelected(x)}
          style={{ padding: 0, }}
        /> : null}
        {networkConn.state.error ? <Alert severity="error" >{networkConn.state.error}</Alert> : null}
      </DWeb.DCol>
      <DWeb.DCol style={{ background: dcolors.pallets.amber50, height: '50vh', padding: 10, borderTop: '3px solid black' }}>
        {!selected ? <Alert severity="error" >Please select some item from top</Alert> : null}
        {selected ? <DWeb.Html html={getDetailsText(selected)} /> : null}
      </DWeb.DCol>
    </div>)
  }

  // Serach button with Text
  export function SearchButton({ onSearch }: { onSearch: TStringCallback }) {
    const [searchText, setSearchText] = useState('');
    const handleSearch = () => {
      // Pass the search text to the parent component or perform the search logic here
      onSearch(searchText);
    };

    const handleInputChange = (event: any) => {
      setSearchText(event.target.value);
    };
    return <div style={{}}>
      <input
        //label="Search"
        // variant="outlined"
        value={searchText}
        onChange={handleInputChange}
        //size="small"
        style={{ border: '1px solid grey', height: 32, padding: 4 }}
      />
      <Button variant="contained" onClick={handleSearch} size="small">
        Search
      </Button>
    </div>
  }

  // tool tips with hints
  export function DTooltip({ hints }: { hints: string }) {
    return <Tooltip title={hints}><span style={{ marginLeft: 5 }}>ⓘ</span></Tooltip>
  }

  // full screen overlay
  export function FullScreenOverlay({ children, onClose }: { children: any, onClose: TVoidCalBack }) {
    return (<div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'white', width: '100%', height: '100%', zIndex: 11 }}>

      <IconButton style={{ position: 'fixed', top: 10, right: 10 }} onClick={onClose}> <Close /></IconButton>
      {children}
    </div>)
  }

  // price Matrix or subscription matrix

  export type TPriceInformation = Array<{
    plan_name: string,
    price: string,
    btn_text?: string,
    btn_link?: string,
    features: Array<{ text: string, hints?: string }>
  }>
  export function PriceMatrix({ priceInformation, style }: {
    priceInformation: TPriceInformation,
    style?: CSSProperties
  }) {
    return (<div style={{ maxWidth: 840, margin: '0 auto', ...style }}>
      <DWeb.DCol >
        <DWeb.DText style={{ fontSize: 36, textAlign: 'center', marginBottom: 40 }}>Upgrade to Grodok Pads Pro</DWeb.DText>
        <DWeb.DRow>
          {priceInformation.map((x, idx) => (

            <DWeb.DCol key={idx} style={{
              border: '1px solid #d9dadb',
              borderRadius: 8,
              padding: 24, textAlign: 'center',
              margin: 12
            }}>
              <DWeb.DText style={{ color: '#0074d9', fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
                {x.plan_name}<br /> <span style={{ fontSize: 14 }}>{x.price}</span>
              </DWeb.DText>
              {x.features.map((y, idy) => (<DWeb.DText style={{ textAlign: 'left' }}><span style={{ color: 'green', paddingRight: 10 }}>✔</span>{y.text} {y.hints ? <DWeb.DTooltip hints={y.hints} /> : null}</DWeb.DText>))}
              {x.btn_text ? (<Button href={x.btn_link} variant='contained' size='small' style={{ marginTop: 20, flex: 0 }}>{x.btn_text}</Button>) : null}
            </DWeb.DCol>
          ))}
        </DWeb.DRow>
        <DWeb.DText style={{ marginTop: 30, textAlign: 'center' }}>Payments processed securely with Stripe.<br></br><img style={{ marginTop: 10 }} src="https://codeshare.io/-/img/powered_by_stripe@3x.png" width="120" alt="Powered by Stripe"></img></DWeb.DText>

      </DWeb.DCol>

    </div>)
  }




  // write code above this line<div class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiTableContainer-root css-11xur9t-MuiPaper-root-MuiTableContainer-root" style="padding: 0px;">
}
