import "./onepager.css"
import "./onepager_override.css"

export type TOnePagerConfig = {
    // hero
    hero_section?: {
        hero_title: string,
        hero_subtitle: string,
        primary_btn_title: string,
        primary_btn_link: string,
        primary_btn_subtext: string,
        hero_image_src: string,
    },
    // middle sections
    generic_section?: {
        text_with_image?: {
            subtitle: string,
            strip_image: any,
        },
        groupOfThree?: {
            title: string,
            subtitle: string,
            btn_text: string,
            btn_link: string
        }[]
    }[],
    // footer
    footer_section: {
        author_text: string,
        author_link: string,
        author_email: string,
        privacy_link: string,
        toc_link: string,
    }
}

export function OnePager({ config, extraElement }: { config: TOnePagerConfig, extraElement?: any }) {
    return (<div id="home">
        {extraElement}
        {config.hero_section ? <section className="hero hero--home">
            <div className="container" style={{ paddingTop: 150 }}>
                <h1>{config.hero_section.hero_title}</h1>
                <h2 style={{ marginTop: 15 }}>{config.hero_section.hero_subtitle}</h2>
                <div className="aligncenter">
                    <p><a className="btn-primary" href={config.hero_section.primary_btn_link} rel="nofollow">{config.hero_section.primary_btn_title}</a></p>
                    <p><small>{config.hero_section.primary_btn_subtext}</small></p>
                </div>
                <div className="group codeshare-examples">
                    <img src={config.hero_section.hero_image_src} />
                </div>
            </div>
        </section> : null}
        {
            config.generic_section?.map((x, idx) => {
                return (<section className="features features--home">
                    <div className="container">
                        <div className="aligncenter">
                            {x.text_with_image ? (
                                <>
                                    <p><small>{x.text_with_image.subtitle}</small></p>
                                    <p><img src={x.text_with_image.strip_image} alt="Some of our users" width="800" /></p>
                                </>
                            ) : null}

                            {x.groupOfThree ? (
                                <div className="group">
                                    {x.groupOfThree?.map((y, idy) => (
                                        <div className="span4">
                                            <h3>{y.title}</h3>
                                            <p>{y.subtitle}</p>
                                            <p><a className="btn-secondary btn-sm" href={y.btn_link} rel="nofollow">{y.btn_text}</a></p>
                                        </div>))
                                    }

                                </div>
                            ) : null}
                        </div>
                    </div>

                </section>)
            })
        }
        <footer className="style.aligncenter">
            <div className="container">
                <p>Created by <a rel="noopener" target="_blank" href={config.footer_section.author_link}>{config.footer_section.author_text}</a>. For help and support shoot us an <a href={config.footer_section.author_email} rel="noopener" target="_blank">email</a>.</p>
                <p><a rel="noopener" target="_blank" href={config.footer_section.privacy_link}>Privacy Policy</a>&nbsp;•&nbsp;<a rel="noopener" target="_blank" href={config.footer_section.toc_link}>Terms of Service</a></p>
            </div>
        </footer>
    </div>)
}

