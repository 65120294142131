import { TObject, dlog, dnetwork } from "corexxx";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from 'react-cookie';
import { useRecoilState } from "recoil";
import { TPadInfo } from "../pads/helper/EditorHook";
import { DWeb } from "./DWeb";
import { DWebTS } from "./DWebTS";

export namespace DHook {
  /*
 Ho to use?
const path = useReactPath();
React.useEffect(() => {
  // do something when path changes ...
}, [path]);
*/
  export const useReactPath = () => {
    const [path, setPath] = React.useState(window.location.pathname);
    const listenToPopstate = () => {
      const winPath = window.location.pathname;
      setPath(winPath);
    };
    React.useEffect(() => {
      window.addEventListener("popstate", listenToPopstate);
      return () => {
        window.removeEventListener("popstate", listenToPopstate);
      };
    }, []);
    return path;
  };

  export function useDeviceType() {
    const [deviceType, setDeviceType] = useState<"mobile" | "tablet" | "desktop" | null>(null);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setDeviceType("mobile");
        } else if (window.innerWidth < 1024) {
          setDeviceType("tablet");
        } else {
          setDeviceType("desktop");
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return deviceType;
  }

  interface ArrayHelpers<T> {
    push: (item: T) => void;
    pop: () => void;
    clear: () => void;
    removeAtIndex: (index: number) => void;
    insertAtIndex: (index: number, item: T) => void;
    move: (fromIndex: number, toIndex: number) => void;
    filter: (callback: (item: T) => boolean) => void;
    map: <U>(callback: (item: T) => U) => void;
    reduce: <U>(callback: (total: U, item: T) => U, initialValue: U) => void;
    updateAtIndex: (index: number, item: T) => void;
  }

  export function useArray<T>(initialValue: T[]): [T[], ArrayHelpers<T>] {
    const [array, setArray] = useState<T[]>(initialValue);

    function push(item: T) {
      setArray([...array, item]);
    }

    function pop() {
      const newArray = [...array];
      newArray.pop();
      setArray(newArray);
    }

    function clear() {
      setArray([]);
    }

    function updateAtIndex(index: number, item: T) {
      const newArray = [...array];
      newArray[index] = item;
      setArray(newArray);
    }

    function removeAtIndex(index: number) {
      const newArray = [...array];
      newArray.splice(index, 1);
      setArray(newArray);
    }

    function insertAtIndex(index: number, item: T) {
      const newArray = [...array];
      newArray.splice(index, 0, item);
      setArray(newArray);
    }

    function move(fromIndex: number, toIndex: number) {
      const newArray = [...array];
      const item = newArray.splice(fromIndex, 1)[0];
      newArray.splice(toIndex, 0, item);
      setArray(newArray);
    }

    function filter(callback: (item: T) => boolean) {
      const newArray = array.filter(callback);
      setArray(newArray);
    }

    function map<U>(callback: (item: T) => U) {
      const newArray = array.map(callback);
      //@ts-ignore
      setArray(newArray);
    }

    function reduce<U>(callback: (total: U, item: T) => U, initialValue: U) {
      const result = array.reduce(callback, initialValue);
      //@ts-ignore
      setArray([result]);
    }

    const helpers: ArrayHelpers<T> = {
      push,
      pop,
      clear,
      removeAtIndex,
      insertAtIndex,
      move,
      filter,
      map,
      reduce,
      updateAtIndex
    };

    return [array, helpers];
  }

  interface ObjectHelpers<T> {
    set: (key: keyof T, value: T[keyof T]) => void;
    remove: (key: keyof T) => void;
    reset: (givenValue?: T) => void;
    update: (key: keyof T, updater: (value: T[keyof T]) => T[keyof T]) => void;
    merge: (newObject: Partial<T>) => void;
  }

  export function useObject<T extends object>(initialValue: T): [T, ObjectHelpers<T>] {
    const [object, setObject] = useState<T>(initialValue);

    function set(key: keyof T, value: T[keyof T]) {
      setObject({ ...object, [key]: value });
    }

    function remove(key: keyof T) {
      const { [key]: _, ...newObject } = object;
      //@ts-ignore
      setObject(newObject);
    }

    function reset(givenValue?: T) {
      setObject(givenValue || initialValue || {});
    }

    function update(key: keyof T, updater: (value: T[keyof T]) => T[keyof T]) {
      const value = object[key];
      set(key, updater(value));
    }

    function merge(newObject: Partial<T>) {
      setObject({ ...object, ...newObject });
    }

    const helpers: ObjectHelpers<T> = {
      set,
      remove,
      reset,
      update,
      merge,
    };

    return [object, helpers];
  }

  export type TItem = {
    _id: string;
    ts_insert: string;
    ts_update: string
  }

  // This is a hook for Simplestore
  // This must be used your own hook
  export function useSimpleStore<T extends TItem>(recoilAtom: any, url: string, config?: DWeb.TRequestOverride) {
    const [data, setData] = useRecoilState<T[]>(recoilAtom);
    const [currentItem, setCurrentItem] = useState<T>()
    const appCommand = DWeb.useAppCommand()
    const [loading, setLoading] = useState(false)

    // Create a new item in the database
    const createItem = async (item: T) => {
      appCommand.fireAndForget(async () => {
        setLoading(true);
        (item as TObject)._id = undefined
        let res = await appCommand.postSimpleStore(url + '/create', item, config)
        let new_item = res.out[0]
        setData([new_item, ...data]);
        setLoading(false)
        setCurrentItem(new_item);
      })
    };

    // Update an existing item in the database
    const updateItem = async (_id: string, input: TObject) => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        await appCommand.postSimpleStore(url + '/update', { ...input, _id: _id }, config)
        await getOne(_id, false)
        setLoading(false)
      })
    };

    // Delete an item from the database
    // pass silentDelete as true to avoid confirmation dialog
    const deleteItem = async (_id: string, silentDelete = false) => {
      if (silentDelete) {
        await appCommand.postSimpleStore(url + '/delete', { _id: _id }, config)
        setData(data.filter((item) => item._id !== _id));
        return;
      }
      DWebTS.showConfirmationAndDo(async () => {
        appCommand.fireAndForget(async () => {
          setLoading(true)
          try {
            await appCommand.postSimpleStore(url + '/delete', { _id: _id }, config)
            setData(data.filter((item) => item._id !== _id));
          } catch (e) { }
          setLoading(false)
        })
      })
    };

    const getOne = async (_id: string, cache: boolean = true) => {
      if (cache) {
        let res = data.filter(x => x._id == _id)?.[0]
        if (res) {
          setCurrentItem(res)
          return res;
        }
      }
      let res = await appCommand.postSimpleStore(url + '/get', { _id: _id }, config)
      let new_item = res.out[0]
      setCurrentItem(new_item)
      setData(data.map((item) => (item._id === _id ? new_item : item)));
    }

    const findOne = async (filter: TObject): Promise<TPadInfo | null> => {
      let res = await appCommand.postSimpleStore(url + '/get', filter, config)
      if (res.out.length == 0) {
        return null
      }
      return res.out[0]
    }


    // Get all items from the database
    const getAllItems = async () => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          let res = await appCommand.postSimpleStore(url, { _limit: 100 }, config)
          setData(res.out)
        } catch (e) { }
        setLoading(false)
      })
    };

    const subEntryAdd = async (_id: string, key: string, value: TObject) => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.postSimpleStore(url + '/subentry/add', { _id: _id, key: key, value: value }, config)
          await getOne(_id, false)
        } catch (e) { }
        setLoading(false)
      })
    }

    const subEntryUpdate = async (_id: string, key: string, entry_id: string, value: TObject) => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.postSimpleStore(url + '/subentry/update', { _id: _id, entry_id: entry_id, key: key, value: value }, config)
          await getOne(_id, false)
        } catch (e) { }
        setLoading(false)
      })
    }

    const subEntryDelete = async (_id: string, key: string, entry_id: string) => {
      appCommand.fireAndForget(async () => {
        setLoading(true)
        try {
          await appCommand.postSimpleStore(url + '/subentry/remove', { _id: _id, entry_id: entry_id, key: key }, config)
          await getOne(_id, false)
        } catch (e) { }
        setLoading(false)
      })
    }

    return {
      data: data,
      currentItem,
      loading: loading,
      api: {
        create: createItem,
        update: updateItem,
        delete: deleteItem,
        getAll: getAllItems,
        getOne: getOne,
        findOne: findOne,
        subEntryAdd: subEntryAdd,
        subEntryDelete: subEntryDelete,
        subEntryUpdate: subEntryUpdate,
        setCurrentItem: setCurrentItem

        //search: serach
      }
    }
  }

  export const useAsyncEffect = (effect: any, dependencies: any) => {
    const isMountedRef = useRef(true);

    useEffect(() => {
      const cleanup = effect();

      return () => {
        isMountedRef.current = false;
        if (typeof cleanup === 'function') {
          cleanup();
        }
      };
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

    return isMountedRef;
  };


  // Make sure this works for string only
  export const useStateWithCookie = (initialState: string, cookieName: string): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [state, setState] = useState<string>(initialState);
    const [cookies, setCookie] = useCookies([cookieName]);

    useEffect(() => {
      const savedState = cookies[cookieName];
      dlog.d(`Restore for ${cookieName} to ${savedState}`)
      if (savedState) {
        if (typeof (savedState) == 'object') {
          setState(JSON.stringify(savedState, null, '\t'))
        } else {
          setState(savedState.toString());
        }
      }
    }, []);

    useEffect(() => {
      setCookie(cookieName, state, { path: '/' });
    }, [state]);

    return [state, setState];
  };

  // This hook will abstrcut JSOn network request
  export const useNetwork = (url: string, isSimpleStoreEndpoint = false) => {
    const [data, setData] = useState<TObject | null>(null);
    const [params, setParams] = useState<TObject | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await dnetwork.postJson(url, params || {}) as any;
        if (isSimpleStoreEndpoint) {
          setData(response.out as TObject)
        } else {
          setData(response);
        }
        setError(null);
      } catch (error: any) {
        setError(error.msg);
      }
      setLoading(false);
    };
    useEffect(() => {
      fetchData();
    }, [url, params]);
    const reload = () => {
      fetchData();
    };

    // You must return as state and api
    return {
      state: {
        data: data,
        error: error,
        loading: loading,
        params: params
      },
      api: {
        reload: reload,
        setParams: (obj: TObject) => setParams(obj),
      }
    }
  }
}
