import { dlog } from "corexxx";
import { BasePlugin } from "./BasePlugin";

export class ParticipantListPlugin extends BasePlugin {
    status1 = 'Unknown'
    public override onConnected(editor: any, firepadRef: any) {
        this.status1 = "connected"
        dlog.d("onConnected called")
    }
    public override onDisconnected(editor: any, firepadRef: any) {
        this.status1 = "disconnected"
        dlog.d("onConnected called")
    }
    public override getMenuBarComponent(): () => JSX.Element {
        return () => <p>${this.status1}</p>
    }
}