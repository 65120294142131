import { dlog } from "corexxx"
import { DWeb } from "../DWeb"
import { DWebTS } from "../DWebTS"

export const ComponentTestScreen = () => {
    return <DWeb.ResponsiveWindow>
        <DWeb.DText>This page for testing the components</DWeb.DText>
        <DWeb.RichTextEditor onChange={(a) => { }} style={{}} onSave={(a) => { dlog.d(a) }} initValue="<p><b>TEST</b></p>" />
        <DWeb.DText>This page for testing the components</DWeb.DText>
        <DWeb.DBox style={{ flex: 0 }}>
            <DWeb.DForm
                model={{
                    data: [
                        {
                            type: "files",
                            name: "files",
                            required: true
                        }
                    ]
                }}
                onSubmit={async (obj) => {
                    console.log(await DWebTS.uploadFiles(obj.files, { user_id: 'test' }))
                }}
                config={
                    { title: 'hello', trigger_btn_text: 'Submit', noClearOnSubmit: true }
                }
            />
        </DWeb.DBox>
        <DWeb.DBox>
            <DWeb.DSplitButton actions={[{ text: 'One', onClick: () => { } }, { text: 'One', onClick: () => { } }, { text: 'One', onClick: () => { } }, { text: 'One', onClick: () => { } },
            ]} />
        </DWeb.DBox>

    </DWeb.ResponsiveWindow>
}