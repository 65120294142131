import { OnePager, TOnePagerConfig } from "../../libs/components/simpleonepager/OnePager"
import { DashboardMenuBar } from "./Dashboard"

export const example: TOnePagerConfig = {
    hero_section: {
        hero_title: 'Free Unlimited Real-time Collaborative Pads for Developers.',
        hero_subtitle: 'Versatile online code editor for interviews, troubleshooting, teaching, and beyond.',
        primary_btn_title: 'Share code for Free',
        primary_btn_link: '/new',
        primary_btn_subtext: 'Free code-sharing tool. No registration needed. Pads expire in 24 hours.',
        hero_image_src: require('../assart/img/demo.png')
    },

    generic_section: [
        {
            text_with_image: {
                subtitle: 'Used by software engineers at companies and universities we respect and admire.',
                strip_image: require('../assart/img/users.png'),
            }
        }, {
            groupOfThree: [
                {
                    title: 'Code with your team',
                    subtitle: 'Open a Codeshare editor, write or copy code, then share it with friends and colleagues. Pair program and troubleshoot together.',
                    btn_text: 'Hack together',
                    btn_link: '/new'
                },
                {
                    title: 'Interview developers',
                    subtitle: 'Set coding tasks and observe in real-time when interviewing remotely or in person. Nobody likes writing code on a whiteboard..',
                    btn_text: 'Start an Interview',
                    btn_link: '/new'
                },
                {
                    title: 'Teach people to program',
                    subtitle: 'Share your code with students and peers then educate them. Universities and colleges around the world use Codeshare every day.',
                    btn_text: 'Teach Code',
                    btn_link: '/new'
                },
            ]
        }],
    footer_section: {
        author_text: 'Dipankar Dutta',
        author_email: 'mailto:hello@grodok.com',
        author_link: 'https://linkedin.com/in/dipankar08',
        privacy_link: 'https://linkedin.com/in/dipankar08',
        toc_link: 'https://linkedin.com/in/dipankar08',
    }
}
export const LadingPage = () => {
    return (<div>
        <DashboardMenuBar style={{ background: 'transparent', position: 'absolute', paddingBottom: 100 }} />
        <OnePager config={example} />
    </div>)
}