// @ts-ignore
import { InfoRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ExtensionIcon from '@mui/icons-material/Extension';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SettingIcon from "@mui/icons-material/Settings";
import { Button, IconButton, Switch } from "@mui/material";
import { TVoidCalBack, dcolors, drandom } from "corexxx";
import React, { CSSProperties } from "react";
import { DHook } from '../../libs/DHook';
import { DWeb } from "../../libs/DWeb";
import { DWebTS } from '../../libs/DWebTS';
import { EditorConst } from '../helper/EditorCont';
import { EditorHelper } from '../helper/EditorHelper';
import { useEditorHook } from "../helper/EditorHook";
import { PluginsManager } from '../plugins/PluginsManager';

import "./style_override.css";


export const Pad = () => {
    // Helper to get hash from end of URL or generate a random one.
    const appCommand = DWeb.useAppCommand();

    const [expanded, setExpanded] = React.useState(false)
    const [subscriptionPromo, setSubsciptionPromo] = React.useState(false)



    let data = { name: `${appCommand.accountState?.username || 'Guest'}(${Math.floor(Math.random() * 10)})`, color: drandom.getRandomColor() }
    const editorData = useEditorHook()

    DHook.useAsyncEffect(async () => {
        if (editorData.setupReady) {
            try {
                await editorData.createOrGetPad()
            } catch (e: any) {
                editorData.setError(`not able to find the pad! ${e.message}`)
            }
        }
    }, [editorData.setupReady])

    function moveToAccount() {
        appCommand.navigate('/account')
    }

    // Save automatically
    React.useEffect(() => {
        const yourPeriodicFunction = () => {
            if (appCommand.accountState?._id) {
                //@ts-ignore
                editorData.padInfoStore.api.update(editorData.padInfo._id, { data: EditorHelper.getInstance().editor?.getValue() })
            }
        };
        const intervalId = setInterval(yourPeriodicFunction, 15000); // 5000 milliseconds (adjust as needed)
        return () => clearInterval(intervalId)
    }, []);


    return (
        <div style={{ height: '100vh', width: '100wh', background: 'black', fontFamily: 'monospace' }}>
            {(editorData.error?.length || 0) > 0 ? (
                <div style={{
                    height: '100vh',
                    zIndex: 100,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    backdropFilter: 'blur(10px)',
                    color: 'white'
                }}>
                    <DWeb.DCenter style={{ height: '100vh', width: 400, margin: '0 auto' }}>
                        <DWeb.DText style={{ color: 'white', fontSize: 20, marginBottom: 10 }}>🚫 Something went wrong! 🚫 </DWeb.DText>
                        <DWeb.Html style={{ fontSize: 16, marginBottom: 30, textTransform: 'initial', textAlign: 'left', color: '#ffffffaa' }} html={`We found some issue while opening this pad.The error could be anything link:<br> a) Either this pad doesn't exist or<br> b) you don't have permission to open this pad.<br><br> Here is the error:${editorData.error}`}></DWeb.Html>
                        <DWeb.DRow style={{ flex: 0 }}>
                            <Button
                                disabled={!editorData.allowCreate()}
                                size="small" variant='contained' style={{ marginRight: 10, background: dcolors.pallets.red400 }} onClick={() => editorData.createNewPad()}>
                                Create a Free Pad
                            </Button>
                            <Button
                                disabled={!appCommand.isLoggedIn}
                                size="small" variant='contained' style={{ background: dcolors.pallets.blue400 }} href="/dashboard">
                                Go to your account
                            </Button>
                        </DWeb.DRow>
                    </DWeb.DCenter>
                </div>
            ) : null}

            <div style={{ height: 'calc(100% - 50px)', width: `calc(100% - ${expanded ? '300px' : '50px'})`, marginTop: 50, marginRight: 50 }} id="firepad" ></div>


            <DWeb.DRow style={{ padding: 5, zIndex: 10, position: 'fixed', top: 0, left: 0, right: 0, width: '100%', height: 50, background: '#282c33', alignItems: 'center' }}>
                <DWeb.DImage src={require('../assart/img/icon.png')} href="/" style={{ height: 30, marginLeft: 10 }} />

                <DWeb.DSpace />
                <div>{Object.values(editorData.users).map((x: any, idy) => <span key={idy} style={{ color: x.color, marginRight: 5, height: 30 }}>⬤ {x.name}</span>)}</div>
                {
                    PluginsManager.getInstance().getPluginList().map((y, idx) => y.getMenuBarComponent())
                }


                {editorData.padExpHrs ? <DWeb.DText style={{ color: dcolors.pallets.pink400, padding: 5, fontSize: 15 }}>Expiring in {editorData.padExpHrs.toFixed(1)}hrs</DWeb.DText> : null}


                {!appCommand.state.has_subscription ? <Button size='small' variant='contained'
                    style={{ background: dcolors.pallets.pink400, color: 'white', borderColor: "white", maxHeight: 40, marginRight: 10 }}
                    onClick={() => setSubsciptionPromo(true)}
                > ✰ Subscribe</Button> : null}

                <Button size='small' variant='contained'
                    style={{ display: 'none', background: dcolors.pallets.pink400, color: 'white', borderColor: "white", maxHeight: 40, marginRight: 10 }}
                    onClick={() => {
                        // @ts-ignore
                        appCommand.accountState?._id ? editorData.editorData.padInfoStore.api.update(editorData.padInfo._id, { data: EditorHelper.getInstance().editor?.getValue() || '' }) : moveToAccount()
                    }}
                >Save</Button>
                {appCommand.accountState?._id ?
                    <Button size='small' style={{ display: 'none', color: 'white', borderColor: "white", maxHeight: 40 }} variant="outlined" href="/account">Account</Button>
                    : <Button variant="outlined" href="/account" style={{ display: 'none', color: 'white', borderColor: "white", height: 40 }}>login</Button>}

            </DWeb.DRow>

            <DWeb.DCol className="cf" style={{ padding: 0, position: 'fixed', zIndex: 10, top: 50, right: 0, bottom: 0, width: expanded ? 300 : 50, background: '#282c33' }}>
                <RightPanel expanded={expanded} setExpanded={setExpanded} args={[
                    {
                        key: 'settings',
                        title: 'Settings',
                        icon: <SettingIcon />,
                        details: <>
                            <DWeb.DText style={{ marginTop: 20, marginBottom: 5 }}>Theme</DWeb.DText>
                            <DWeb.DSelectInput value={editorData.theme} onChange={editorData.setTheme} options={EditorConst.theme.map((x: any) => ({ value: x[0], text: x[1] }))}></DWeb.DSelectInput>

                            <DWeb.DText style={{ marginTop: 20, marginBottom: 5 }}>Syntax</DWeb.DText>
                            <DWeb.DSelectInput value={editorData.syntax} onChange={editorData.setSyntax} options={EditorConst.syntax.map((x: any) => ({ value: x[0], text: x[1] }))}></DWeb.DSelectInput>

                            <DWeb.DText style={{ marginTop: 20, marginBottom: 5 }}>Font Size</DWeb.DText>
                            <DWeb.DSelectInput value={editorData.fontSize} onChange={editorData.setFontSize} options={EditorConst.fontSize.map((x: any) => ({ value: x[0], text: x[1] }))}></DWeb.DSelectInput>



                            <DWeb.DText style={{ marginTop: 20, marginBottom: 5 }}>Keymap</DWeb.DText>
                            <DWeb.DSelectInput value={editorData.keymap} onChange={editorData.setkeymap} options={EditorConst.keyMap.map((x: any) => ({ value: x[0], text: x[1] }))}></DWeb.DSelectInput>

                            <DWeb.DRow style={{ alignItems: 'center', flex: 0, marginTop: 20 }}>
                                <DWeb.DText style={{ flex: 1 }}>Show Whitespace</DWeb.DText>
                                <Switch value={editorData.showWhiteSpace} onChange={e => editorData.setShowWhiteSpace(e.target.checked)}></Switch>
                            </DWeb.DRow>
                        </>
                    },
                    {
                        key: 'download',
                        title: 'Download Code',
                        icon: <DownloadIcon />,
                        disableExpand: true,
                        onClick: () => {
                            // @ts-ignore
                            DWebTS.downloadAsFile(EditorHelper.getInstance().editor?.getValue() || 'None', "hello.txt")
                        },

                    },
                    {
                        key: 'Info',
                        title: 'Pad Properties',
                        icon: <InfoRounded />,
                        details: <>
                            <table>
                                <tr>
                                    <td><p>Name:</p></td>
                                    <td>
                                        <p onClick={() => {
                                            let text = prompt("Give a new name of the Pad?", editorData.padInfoStore.currentItem?.title || "somename")
                                            editorData.padInfoStore.api.update(editorData.padInfoStore.currentItem?._id!!, { title: text })
                                        }}>{editorData.padInfoStore.currentItem?.title || 'Untitled'}</p></td>
                                </tr>
                                <tr>
                                    <td><p>Visibility:</p></td>
                                    <td><p>{editorData.padInfoStore.currentItem?._visibility?.indexOf('public') != -1 ? 'Public' : 'private'}</p></td>
                                </tr>
                                <tr>
                                    <td><p>Read only:</p></td>
                                    <td>{editorData.padInfoStore.currentItem?.is_read_only ? 'Yes' : 'No'}</td>
                                </tr>
                            </table>
                        </>

                    },
                    {
                        key: 'extension',
                        title: 'Plugins',
                        icon: <ExtensionIcon />,
                        details: <p>Sorry, no plugin is available right now! If you are interested to create a new plug in please reach out to dutta.dipankar08@gmail.com</p>
                    },
                    {
                        key: 'information',
                        title: 'Information',
                        icon: <QuestionMarkIcon />,
                        details: <>

                            <p>👩🏻‍💻 Grodok Pad enables developers to share code in real-time. Write or paste code in your browser, share the URL, code in real-time with friends and team mates.</p>

                            <p>🔨 Grodok Pad was built and maintained by Dipankar Dutta.</p>

                            <p>🗣️ If you have feedback or feature requests, please add/vote <a href="https://dipankar.co.in">here</a>.</p>
                        </>
                    }
                ]} />
            </DWeb.DCol>
            {subscriptionPromo ? <SubscriptionPromo onClose={() => setSubsciptionPromo(false)} /> : null}
        </div>)

}

const SubscriptionPromo = ({ onClose, }: { onClose: TVoidCalBack }) => {

    const priceInformation: DWeb.TPriceInformation = [
        {
            plan_name: "Free - Unsigned",
            price: "$0/mth",
            features: [
                {
                    'text': 'Unlimited Pads'
                },
                {
                    text: "Unlimited Realtime Collaborators"
                },
                {
                    text: 'Expires in 24 hrs'
                },
            ]
        },
        {
            plan_name: "Free - After SignIn",
            price: "$0/mth",
            features: [
                {
                    'text': '20 Saved Pads'
                },
                {
                    text: "Unlimited Realtime Collaborators"
                },
                {
                    text: 'No expiries'
                },
                {
                    text: 'Make pads private'
                },
                {
                    text: 'Make pads readonly'
                },
            ]
        },
        {
            plan_name: "Grodok Pads Pro",
            price: "$9.99/mth",
            features: [
                {
                    text: 'Unlimited Saved Pads',
                    hints: 'Create and save as many pads as you like.',

                },
                {
                    text: "Unlimited Realtime Collaborators",
                    hints: 'share many people as you like.',
                },
                {
                    text: 'No expiries',
                    hints: 'The pads wil be saved for lifetime',
                },
                {
                    text: 'Make pads private',
                    hints: 'No one can access your pad',
                },
                {
                    text: 'Make pads readonly',
                    hints: 'Share your pads in view only mode',
                },
                {
                    text: 'No Ads (including collaborators)',
                    hints: 'No ads would be shown if you using pro',
                },
                {
                    text: 'Default Pads Settings',
                    hints: "Pads settings is saved like tab size, languages etc"
                },
            ],
            btn_text: 'Continue to upgrade',
            btn_link: '/account'
        },
    ]

    return (<DWeb.FullScreenOverlay onClose={onClose}>
        <DWeb.PriceMatrix priceInformation={priceInformation} style={{ marginTop: 100 }} />
    </DWeb.FullScreenOverlay>)
}


const RightPanel = ({ expanded, setExpanded, args }: { expanded: boolean, setExpanded: (a: boolean) => void, args: Array<{ key: string, title: string, icon: JSX.Element, onClick?: TVoidCalBack, details?: JSX.Element, disableExpand?: boolean }> }) => {
    let [view, setView] = React.useState<JSX.Element | null>(null)
    let [selectedIdx, setSelectedIdx] = React.useState(-1)
    return (<DWeb.DRow>
        <DWeb.DCol style={{ width: 50, flex: 0 }}>
            {args.map((a, idx) => <>
                {idx == args.length - 1 ? <DWeb.DSpace key={idx} /> : null}
                <IconButton
                    key={idx + '00'}
                    onClick={() => {
                        if (!a.disableExpand) {
                            setSelectedIdx(idx);
                            setExpanded(true)
                        } else {
                            setExpanded(false)
                        }
                        a.onClick?.();
                    }
                    }
                    style={{
                        flex: 0,
                        padding: 15,
                        fontSize: 10,
                        borderTop: '1px solid #ffffff20',
                        borderRadius: 0,
                        color: selectedIdx != idx ? dcolors.pallets.grey600 : dcolors.pallets.black,
                        background: selectedIdx == idx ? 'white' : 'transparent'
                    }}
                >
                    {a.icon}
                </IconButton></>)}


        </DWeb.DCol>
        {expanded ? (<DWeb.DCol style={{ background: 'white', padding: 10 }}>
            <DWeb.DRow style={{ flex: 0, alignItems: 'center' }}>
                <DWeb.DText mode='title' style={{ flex: 1 }}>{args[selectedIdx].title}</DWeb.DText>
                <IconButton onClick={() => { setExpanded(false); setSelectedIdx(-1) }}>
                    <CloseIcon />
                </IconButton>
            </DWeb.DRow>
            {args[selectedIdx].details}
        </DWeb.DCol>) : null
        }

    </DWeb.DRow >)
}

export const UnicodeButton = ({ text, onClick, style }: { text: string, onClick: TVoidCalBack, style?: CSSProperties }) => {
    return <button style={{ margin: 5, fontSize: 20, color: 'white', background: '#00000000', border: 0, cursor: 'pointer', paddingLeft: 10, paddingRight: 10, fontFamily: 'monospace', ...style }} onClick={onClick}>{text}</button>
}