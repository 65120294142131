// @ts-nocheck >> We need this as the below two lines must be executed first
import { overrideSystemApi } from "./libs/system_api_override";
overrideSystemApi()
///// Later lines
import { dlog } from "corexxx";
import React from "react";
import ReactDOM from "react-dom/client";
import { DWeb } from "./libs/DWeb";
import reportWebVitals from "./reportWebVitals";
// choose one from below
import App from "./pads/App";

dlog.isDev(process.env.NODE_ENV !== "production");
dlog.d("We are runnng dev mode...");
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <DWeb.ErrorBoundary>
      <App />
    </DWeb.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
