export namespace CommonTS {
    export function convertToHtml(text: string): string {
        // Replace telephone numbers with HTML links
        //  text = text.replace(/\b(\d{3})[-.]?(\d{3})[-.]?(\d{4})\b/g, '<a href="tel:$1$2$3">$1-$2-$3</a>');
        // Replace email addresses with HTML links
        // text = text.replace(/\b([\w.-]+@[\w.-]+)\b/g, '<a href="mailto:$1">$1</a>');
        // Replace URLs with HTML links
        text = text.replace(/\b((?:https?:\/\/|www\.)\S+)\b/g, '<a href="$1" target="_blank">link</a>');
        // Replace <p> tags with <span> tags
        text = text.replace(/<p>(.*?)<\/p>/g, '<span>$1</span>');
        return text;
    }
    export function formatFileSize(size: number) {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;

        if (size < kilobyte) {
            return size + ' B';
        } else if (size < megabyte) {
            return (size / kilobyte).toFixed(2) + ' KB';
        } else {
            return (size / megabyte).toFixed(2) + ' MB';
        }
    }

    // get hours diffence 
    export function getHourDifference(isoDateString1?: string, isoDateString2?: string): number | undefined {

        const date1 = new Date(isoDateString1 || '');
        const date2 = new Date(isoDateString2 || '');

        // Check if the date strings are valid
        if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
            console.error("Invalid date strings");
            return undefined;
        }

        // Calculate the time difference in milliseconds
        const timeDifferenceMs = date2.getTime() - date1.getTime();

        // Convert milliseconds to hours
        const hourDifference = timeDifferenceMs / (1000 * 60 * 60);

        return Math.abs(hourDifference);
    }
}